.chat-list {background:  #2e2f44;width: 296px;min-height: 794px;padding: 16px 8px 14px 24px;border-top-left-radius: 12px;border-bottom-left-radius: 12px;}
.chat-list .groups,
.private-messages {margin-bottom: 40px;}
.chat-list .search-input {width: 248px;position: relative;margin-bottom: 28px;}
.chat-list .search-input input {width: 100%;background: #3e3f55;border-radius: 8px;padding: 10px 10px 11px 36px;color: #FFF;}
.chat-list .search-input input ::placeholder,.community-detail .form-group .form-control::placeholder {color: #fff;font-family: 'Gilroy';font-weight: normal;font-size: 16px;}
.input input::placeholder,.textarea textarea::placeholder {color: rgba(255, 255, 255, 0.816);font-family: 'Gilroy';font-weight: normal;font-size: 16px;}
.chat-list .search-icon {position: absolute;top: 20px;left: 12px;}
.chat-list strong {font-family: 'Gilroy';font-weight: 600;font-size: 14px;margin-bottom: 18px;color: #fff;}
.chat-list .msg-count {width: 24px;height: 24px;border-radius: 100%;background: #FFE0E8;color: #BD1722;text-align: center;display: none;}
.chat-list .msg-count span {font-family: 'Gilroy';font-weight: normal;font-size: 10px;}
.chat-list ul li {/* margin-bottom: 28px; */color: #4F4F4F;font-family: 'Gilroy';font-weight: normal;font-size: 16px;padding: 14px 12px;}
.chat-list ul li:hover {background-color: #F8F8F8;border-radius: 8px;}
.chat-list ul .active {color: #333333;font-family: 'Gilroy';font-weight: 600;}
.chat-list .active .msg-count {display: block;}
.private-chat {background-color: #F8F8F8;/* width: 834px; */width:calc(100% - 296px);/* min-height: 795px; */min-height: 920px;padding: 20px 24px;border-top-right-radius: 12px;border-bottom-right-radius: 12px;}
.private-chat strong {font-family: 'Gilroy';font-weight: bold;font-size: 16px;color: #333333;}
.private-chat .top-header span {font-family: 'Gilroy';font-weight: normal;font-size: 14px;color: #219653;margin-bottom: 12px;display: block;}
.private-chat .top-header {border-bottom: 1px solid #E0E0E0;}
.private-chat .body {padding: 31px 28px;min-height: 720px;}
.private-chat .body .msg-tag {padding: 14px 16px;font-family: 'Gilroy';font-weight: normal;font-size: 16px;/* background-color: #FFFFFF; *//* display:block;max-width: 109px; */display: table;border-radius: 12px;margin-bottom: 10px;}
.out-goingMsg{background-color: #FFFFFF;color: #333333;position: relative;}
.out-goingMsg::before{content: '';position: absolute;width: 12px;height: 12px;border-radius: 100%;background-color: #FFFFFF;/* top: 50px; */bottom: 0%;left: -11px;}
.incomingMsg{background-color: #FFE0E8;color: #333333;position: relative;}
.incomingMsg::before{content: '';position: absolute;width: 12px;height: 12px;border-radius: 100%;background-color: #FFE0E8;/* top: 50px; */bottom: 0%;right: -11px;}
.private-chat .send-msg, .chat-section .send-msg{position: relative;}
.private-chat .send-msg input ,.chat-section .send-msg input{background: #FFFFFF;/* Box-Shadow-2 */border-color: transparent;box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);border-radius: 12px;/* padding: 15px 20px; */padding: 15px 114px 15px 20px;}
.chatting .card, .chatting .chat-section{background: #F8F8F8;}
.chatting .chat-section{border-top-right-radius: 12px;border-bottom-right-radius: 12px;box-shadow: none;width:calc(100% - 296px);}
.chatting{margin: 0 30px 0 20px;}
.chatting .chat-section .body{min-height: 720px;}
.private-chat .submit-button ,.chat-section .submit-button{position: absolute;top: 9px;right: 5px;background: linear-gradient(90deg, #0DC7C9, #0074C1) !important;border: none; }
.private-chat .pin-icon ,.chat-section .pin-icon{position: absolute;top: 15px;right: 64px;}
.private-chat .emoji-icon ,.chat-section .emoji-icon{    position: absolute;    top: 15px;    right: 96px;}
.chat-section .main-chats-head{border-bottom: 1px solid #E0E0E0;height: auto;padding-bottom: 12px;}
.backspace{display: none;}
/*responsive*/
@media (max-width: 991.98px) { 
    .chat-list{    display: none;}
    .backspace{    display: block;}/* .private-chat{    display: none;} */
    .chat-list,.chat-list .search-input ,.private-chat, .chatting .chat-section{    width: 100%;}
}
@media (max-width: 360px) { 
  .private-chat .body {padding: 31px 0px;}
  .private-chat .body .msg-tag{font-size: 12px;padding: 8px 16px;}
}