.create-community h3, .create-community .accordion-button {font-size: 18px;line-height: 22px;font-family: 'Gilroy';font-weight: bold;color: #fff;}
.create-community .basic-info-col {padding-right: 90px;}
.community-details{margin: 0 20px;}
.create-community .form-control {height: 48px;border: none;border-radius: 12px;
/* background: #3e3f55; */
background: rgb(255 255 255 / 13%);
border: 1px solid #a4a4a4;
color: #fff;}
.create-community .form-control:focus {box-shadow: none;border-color: #fff;}
.create-community textarea.form-control {height: 144px;}
.create-community .accordion-item {border: none;padding: 20px;
/* background-color: #1a1b2b; */
background: rgb(255 255 255 / 13%);
/* border: 1px solid #a4a4a4; */
}
.create-community .accordion-body label { margin: 0 0 20px;}
.create-community .accordion-button {padding: 0;    background: transparent;}
.create-community .accordion-button:focus {box-shadow: none;border-color: none;}
.create-community .accordion-button:not(.collapsed) {background-color: transparent;box-shadow: none !important;}
.create-community .accordion-button::after {background-image: none;}
.create-community .accordion-button::after {flex-shrink: 0;transition: all ease-in-out 0.3s;right: 0;transform: rotate(45deg);margin-left: auto;position: absolute;content: '';border: 10px solid;border-width: 0 2px 2px 0;width: 16px;height: 16px;}
.accordion-button:not(.collapsed)::after {transform: rotate(225deg);}
.create-community .accordion-body {padding: 25px 0 0;color: #fff;}
.create-community label.form-label {width: 210px;margin: 0 12px 0 0;color: #fff;}
/* .create-community .input-holder {width: calc(100% - 222px);} */
/* .explore-communities .create-community { margin: 0 1%;} */
.create-community .input-holder {width: 100%;}
.explore-communities{margin: 0 30px 0 20px;}
.explore-communities .community-search-form {margin: 0 0 20px;}
.explore-communities .community-search-form .form-control {height: 55px;background-color: transparent;border: none;color: #fff;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;}
.explore-communities .community-search-form .form-control:focus {box-shadow: none;}
.explore-communities .community-search-form .search-icon {position: absolute;left: 20px;top: 50%;-o-transform: translateY(-50%);-ms-transform: translateY(-50%);-moz-transform: translateY(-50%);-webkit-transform: translateY(-50%);transform: translateY(-50%);}
.explore-communities .community-search-form .search-form {padding: 0 0 0 12px;
/* background: #3e3f55; */
background: rgb(255 255 255 / 13%);
border: 1px solid #a4a4a4;
border-radius: 12px;color: #fff;}
.explore-communities .community-search-form .search-form:focus-within{border: 1px solid #ffff;}
.communities-list .community-item {align-items: center;padding: 10px 15px;
/* background:  #2e2f44; */
background-color: rgba(12, 44, 111,0.5);
border-radius: 12px;font-size: 16px;line-height: 24px;font-family: 'Gilroy';color: #fff;
width: 49%;
/* width: 450px; */
margin: 0 2% 0 0;box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);}
.communities-list .community-item:nth-child(even){margin-right: 0;}
.communities-list .community-detail {width: calc(100% - 166px);}
.communities-list .community-item .image-holder {width: 150px;height: 130px;}
.communities-list .community-item .image-holder img{width: 100%;height: 100%;object-fit: fill;overflow: hidden;border-radius: 12px;}
.communities-list .community-name {font-size: 24px;line-height: 28px;font-weight: bold;color: #fff; width: 250px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
.communities-list .community-name a {text-decoration: none;color: #fff;text-transform: capitalize;}
.communities-list .community-name a:hover {color: #2b56f5;}
.communities-list .community-stats {font-size: 16px;line-height: 24px;font-family: 'Gilroy';color: rgb(255 255 255 / 90%);}
.communities-list .community-stats li {padding: 0 10px 0 0;
/* margin: 0 0 15px; */
/* margin: 0 30px 15px 0; */
margin: 0 30px 0px 0;
display: flex;
align-items: end;
flex-wrap: wrap;}
.form-control::placeholder{
	color: #d6d7e0;
	white-space: nowrap;
    /* overflow: hidden;
    text-overflow: ellips;
	width: 120px; */
}
.communities-list .community-stats li:first-child,.communities-list .community-stats li:nth-child(2) {width: 100%;}
.communities-list .community-stats li strong {color: #fff;}
.lower-header {
/* background:  #333554; */
background:rgba(12, 44, 111,0.5);
/* Box-Shadow-2 */margin-bottom: 16px;box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);border-radius: 8px;padding: 8px 24px;}
.lower-header strong {font-family: 'Gilroy';font-weight: bold;font-size: 18px;line-height: 150%;color: #fff;}
.lower-header .nav-pills .nav-link {cursor: pointer;transition: all ease-in-out 0.3s;margin: 0 5px;}
.lower-header .nav-pills .nav-link:hover {color: #fff;background:  linear-gradient(90deg, #0DC7C9, #0074C1);cursor: pointer; min-width: 155px;text-align: center;}
.lower-header .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {color: #fff;background:  linear-gradient(90deg, #0DC7C9, #0074C1);cursor: pointer; min-width: 155px;text-align: center;}
.active-state{display: none;}
.top-header{display: flex;justify-content: end;margin-bottom: 20px;}
/* .lower-header .nav-pills .nav-link.active, .nav-pills .show>.nav-link .active-state{display: block;}
.lower-header .nav-pills .nav-link.active, .nav-pills .show>.nav-link .default-state{display: none;} */
.lower-header .nav-pills .nav-link.active .default-state{display: none;}
.lower-header .nav-pills .nav-link.active .active-state{display: block;}
.lower-header .nav-pills .nav-link {min-width: 155px;text-align: center;color: #ffff;font-family: 'Gilroy';font-weight: normal;font-size: 14px;}
.main-body {
/* background: #2e2f44; */
background: rgba(12, 44, 111,0.5);
box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);border-radius: 8px;padding: 34px 41px;min-height: 664px;margin: 30px 0px 60px;color: #fff;}
.main-body .top-heading {text-transform: capitalize; color: #fff;font-family: 'Gilroy';font-weight: bold;font-size: 18px;}
.main-body .simple-text {font-family: 'Gilroy';font-weight: normal;}
.main-body .dropdown-toggle {background-color: transparent;color: #fff;border: 1px solid #fff;border-radius: 12px;padding: 14px 23px 13px;font-size: 16px;line-height: 19px;font-family: 'Gilroy';font-weight: 600;}
.main-body .dropdown-toggle svg,
.page-header .dropdown-toggle:focus svg,
.page-header .show>.btn-primary.dropdown-toggle svg {fill: #fff;}
.main-body .show>.btn-primary.dropdown-toggle svg{fill: #fff;}
.main-body .show>.btn-primary.dropdown-toggle {background-color: #fff !important;color: #fff !important;border: 1px solid #fff !important;}
.main-body .dropdown-menu {width: 100%; padding: 0;top: 7px !important;overflow: hidden;box-shadow: 0px 4px 32px rgb(0 0 0 / 8%);border: none;}
.main-body .dropdown-menu .dropdown-item:hover {background-color: #FFE0E8;}
.main-body .top-head {margin-bottom: 42px;}
.main-body .card {
/* background: #3e3f55;border: 1px solid #E0E0E0; */
background: rgb(255 255 255 / 13%);
border: 1px solid #a4a4a4;
border-radius: 8px;min-height: 282px;margin-bottom: 20px;/* width: 247px; */}
.main-body .card .card-body {padding: 18px 29px 11px;}
.main-body .card .card-body .invitation-card{padding-top: 65px;}
 .modal-body .input{width: 465px; margin-bottom: 35px;}
.modal-content{border-radius: 8px;box-shadow: 0px 4px 59px rgb(13 13 6 / 40%);}
.modal-body{padding-top: 34px;}
.input-section{margin-bottom: 60px;}
 .input .form-control{color: #828282;font-family: 'Gilroy';font-weight: normal;font-size: 16px;background: #FFFFFF;border: 1px solid #E0E0E0;border-radius: 12px;width: 100%;padding: 12px;box-shadow: 0px 4px 32px rgb(0 0 0 / 8%);}
.modal-header{border-bottom: none;}
.main-body .card .card-body strong, .main-body .card .card-body .plus-icon{color: #fff;font-family: 'Gilroy';font-weight: bold;font-size: 18px;}
.main-body .card .card-body .add-member{    width: 43px;background: transparent;border-radius: 12px;height: 43px;border: 2px solid #fff;}
.main-body .card .title {color: #333333;font-family: 'Gilroy';font-weight: bold;font-size: 16px;margin-bottom: 16px;display: block;position: relative;}
.main-body .card .title::after {position: absolute;border-bottom: 2px solid #fff;content: '';top: 145%;left: 50%;transform: translate(-50%, -50%);width: 30px;}
.main-body .card .email {color: #333333;font-family: 'Gilroy';font-weight: normal;font-size: 16px;display: block;margin-bottom: 26px;}
.main-body .card .vote-count {font-family: 'Gilroy';font-weight: bold;font-size: 16px;color: #333333;display: block;}
.main-body .card .vote-text {font-family: 'Gilroy';font-weight: normal;font-size: 14px;color: #828282;}
.main-body .card-body .tag {background: #F2FAF5;border-radius: 8px;padding: 6px 30px;display: inline-block;}
.main-body .card .card-footer .msg-us {position: relative;}
.main-body .card .card-footer .msg-us::after {position: absolute;content: '';height: 44px;top: -9px;right: -34px;border-right: 1px solid #E0E0E0;}
.main-body .card .card-footer span {color: #828282;font-family: 'Gilroy';font-weight: normal;font-size: 16px;}
.main-body .card-footer {padding: 10px 16px;background-color: white !important;border-top: 1px solid rgba(0, 0, 0, .125);}
.explore-communities .not-found{width: 100%;min-height: 664px;background: #2e2f44;border-radius: 8px;color: #fff;}
/* .create-community-form .text-danger {font-size: 12px;color: red !important;padding: 0 6px;font-family:'Gilroy';font-weight: 600;} */
.member-card .member-img{width:70px;height:70px;border-radius: 50%;overflow: hidden;border: 1px solid #fff;}
.member-card .member-img img{width:100%;height:100%;object-fit: cover;}
.copy-feedback-text{background:linear-gradient(90deg, #0DC7C9, #0074C1);padding:5px 10px;font-size:12px;color:#fff;border-radius:4px;left:50%;bottom:calc(100% + 5px);-o-transform:translateX(-50%);-ms-transform:translateX(-50%);-moz-transform:translateX(-50%);-webkit-transform:translateX(-50%);transform:translateX(-50%);}
.deposit-form{width:480px;}
.deposit-form input{width:100%;padding:12px 40px 12px;}
.deposit-form .muto-icon{width:18px;position: absolute;left:10px;top: 10px;}
.deposit-form .enter-icon{color:#fff;position: absolute;top:15px;right:15px;-o-transform: rotate(90deg);-ms-transform: rotate(90deg);-moz-transform: rotate(90deg);-webkit-transform: rotate(90deg);transform: rotate(90deg);}
.deposit-form button[type="submit"]{border:none;padding:0;}
.terms-conditions, .privacy-policy {
	background: rgba(12, 44, 111,0.5);
}
.bg-background {
	background-image: url('../../assets/images/header-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: 70px;
}
.mt50{margin-top: 50px;}




.upload-btn-wrapper {
	position: relative;
	overflow: hidden;
	display: inline-block;
  }
  
  .file-uploader {
	border: none;
	/* color: gray; */
	background-color: transparent;
	/* padding: 8px 20px; */
	/* border-radius: 8px; */
	font-size: 20px;
	font-weight: bold;
	cursor: pointer !important;
  }
  
  .upload-btn-wrapper input[type=file] {
	/* font-size: 100px; */
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
	padding: 0;
	/* width: 100%; */
	cursor: pointer;
  }
.cover-photo{width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
	border: 1px solid rgb(255 255 255 / 50%);
}
.cover {
    /* height: 190px; */
	height: 170px;
	/* position: relative; */
}
/* .cover-photo-update{position: relative;} */
.profile-holder{
	width: 180px;
	height: 180px;
	border-radius: 100%;
	/* border: 10px solid #fff; */
	border:5px solid rgb(255 255 255 / 90%);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50% , -30%);
}
.profile-holder img{
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-radius: 100%;
}
.edit-cover {
    position: absolute;
	/* top: 50%; */

	/* bottom: -36px; */
	bottom: -14px;
    right: -260%;
	right: 0;
    /* font-size: 36px; */
    width: 50px;
    transform: translate(-50%, -50%);
	height: 50px;
	border-radius: 100%;
	border: 2px solid rgb(255 255 255 / 90%);
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.freedom-card.cover-editor{position: relative;background: transparent;min-height: 240px;}
.camera-circle-dp {
    background: #2e2f44;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #fff;
	position: absolute;
	    top: 197px;
    left: 55%;
	/* top: 68%;
    left: 55%; */
    transform: translate(-50%, -50%);
	cursor: pointer;
}
.camera-circle-dp.upload-btn-wrapper{cursor: pointer;}
/*responsive*/
/* @media only screen and (max-width:1800px) {
.communities-list .community-item{width: 500px;}} */
@media only screen and (max-width:1399px) {
	.create-community .basic-info-col {padding-right: 0;}
	.edit-cover {position: absolute;
		/* top: 50%; */
		/* right: -190%; */
	}
	.profile-holder {width: 180px;height: 180px;}
	.mt50{margin-top: 40px;}
	.camera-circle-dp{    top: 197px;
    left: 57%;}
}
@media only screen and (max-width:1500px) {
.camera-circle-dp {left: 57%;}}
@media only screen and (max-width:1399px) {
	.main-body .card .card-footer .msg-us::after {right: -14px;}
	/* .communities-list .community-item {min-height: 320px;} */
}
@media only screen and (max-width:1199px){
	.communities-list .community-item {width: 100%;margin:0;}
	.communities-list .community-item {min-height: auto;}
	/* .edit-cover {
		right: -170%;} */
		.camera-circle-dp {left: 60%;}
		.mt50{margin-top: 30px;}
		.cover {height: 125px;}
		.edit-cover {
			/* bottom: 45px; */
			bottom: 28px;
		}
}
@media only screen and (max-width:991px){
	.camera-circle-dp {left: 58%;    width: 40px;
		height: 40px;}
		.bg-background{padding-top: 140px;}
}
@media only screen and (max-width: 880px){
.camera-circle-dp {
    left: 60%;}}
@media (max-width:767px) {
	.page-header .btns-holder {justify-content: space-around;width: 100%;}
	.freedom-solid-btn {align-items: center;padding: 14px 10px 13px;}
	.main-body .dropdown {width: 48%;}
	.page-header .dropdown-toggle {width: 100% !important;}
	.freedom-btn .freedom-solid-btn {padding: 14px 10px 13px;text-align: center;width: 49%;}
	/**/
	.explore-communities .community-search-form .search-form {margin: 0 0 20px;text-align: center;}
	.explore-communities .community-search-form .freedom-btn {margin: 0 auto !important;
		/* min-width: 100%; */
	}
	.deposit-form{width:100%;}
	/* .edit-cover {
		right: -130%;
	} */
	.camera-circle-dp {left: 61%;}
	.mt50{margin-top: 20px;}
}
/* @media only screen and (max-width:720px) {.edit-cover {
	right: -100%;
}} */
@media only screen and (max-width:680px) {
.camera-circle-dp {
    left: 64%;
}}
@media only screen and (max-width:575px) {
	.main-body .card .card-footer .msg-us::after {right: -60px;}
	.communities-list .community-stats li {width: 100%;}
	.btns-holder {justify-content: space-around;}
	.d-flex.nav.nav-pills{flex-direction: column;}
	/* .edit-cover {
		right: -80%;
	} */
	.lower-header .nav-pills .nav-link.active, .nav-pills .show>.nav-link{    margin: 0 0 10px 0;}
	.camera-circle-dp {left: 68%;    width: 40px;
		height: 40px;top: 190px;}
		.community-item.mb-4.d-flex{flex-direction: column;}
		.communities-list .community-detail{width: 100%;}
}
@media only screen and (max-width:479px) {
	.communities-list .community-name {font-size: 20px;width: 100%;}
	.communities-list .community-item .image-holder {
		width: 100px;
		height: 100px;
	}
	.camera-circle-dp {
    left: 64%;
    width: 40px;
    height: 40px;
    top: 166px;
}
	.edit-cover {

		/* top: 40px;
    right: -34px; */
	top: 69px;
    right: -43px;
	}
	.lower-header .nav-pills .nav-link {
	/* padding: 0 34px; */
	margin: 0 0 10px 0;}
	.main-body .dropdown {width: 38% !important;}
	.page-header .dropdown-toggle {width: 100% !important;}
	.freedom-btn .freedom-solid-btn {padding: 14px 10px 13px;text-align: center;width: 39% !important;}
	.page-header .btns-holder>* {font-size: 12px !important;}
	.create-community .accordion-button::after {width: 12px;height: 12px;}
	/* .create-community .create-community-buttons{flex-direction: column !important;}
	.freedom-card .create-community .create-community-buttons{flex-direction: row !important;} */
	.create-community .create-community-buttons button{margin: 0 0 15px;}
	/* .create-community .accordion-item {padding: 10px 0px;} */
	.create-community .accordion-item {padding: 10px;}
	.create-community .input-holder {width: 100%;}
	.communities-list .community-item {width: 100%;flex-direction: column;}
	.communities-list .community-detail {width: 100%;}
	.image-holder.mb-3.mb-md-0.me-3 {margin: 0 auto 20px !important;}
	.main-body {padding: 34px 12px;}
	.d-flex.nav.nav-pills{flex-direction: column;}
	.profile-holder {width: 120px;height: 120px;}
}
/* @media only screen and (max-width:480px) {
	.edit-cover {
		right: -168%;
		top: -62px;
	}} */
@media only screen and (max-width:460px) {
	/* .edit-cover {right: -160%;} */
	.profile-holder {width: 120px;height: 120px;}
		.camera-circle-dp{    left: 66%;
			top: 164px;}
}
@media only screen and (max-width:420px) {
.edit-cover {
	/* right: -154%; */
	/* top: -65px; */
}
}
@media only screen and (max-width:400px) {
	.create-community .accordion-button {font-size: 13px !important;}
/* .edit-cover {
    right: -134%;} */
}
@media only screen and (max-width:360px) {
	.create-community .accordion-button::after {
		width: 7px;
		height: 7px;
	}

	.main-body .card .card-footer .msg-us::after {right: -14px;}
	.btns-holder {flex-wrap: wrap;}
	.heading-col a {font-size: 12px;}
	.freedom-btn .freedom-solid-btn {width: 50% !important;}
	.page-header .btns-holder>* {font-size: 12px !important;margin-bottom: 14px !important;}
	.edit-cover {
		/* right: -115%; */
	}
	/* .camera-circle-dp {left: 65%;top: 200px;} */
	.camera-circle-dp{    left: 68%;
    top: 170px;}
	.communities-list .community-name{width: 100%;}
	.create-community .accordion-body label {font-size: 13px;}
	span.copyright.margin-top {font-size: 14px;padding: 10px 0;}
}

/* @media only screen and (max-width:320px) {
.edit-cover {
    right: -94%;
}
} */
@media only screen and (max-width:280px) {
.create-community .accordion-button {
    font-size: 10px !important;
}
span.copyright.margin-top {
    font-size: 9px;
    padding: 10px 0;
}
.camera-circle-dp {
    left: 75%;
    top: 174px;
}
.create-community .accordion-body label {font-size: 12px;}
}
.simple-text {cursor: pointer;}