.footer-bg{background-image: url('../../assets/images/footer-bg.png');background-size: cover;width: 100%;height: 100%;background-position: center;}
#footer{padding-top: 80px;}
#footer .footer-content .social{text-align: center; color: #fff;position: relative;z-index: 9999;}
#footer .footer-content .social .social-icons {display: inline-block;position: relative;padding: 0px 100px;}
#footer .footer-content .social ul li{margin: 0 0 0 15px;display: inline;}
#footer .footer-content .social ul li a{transition: all .3s ease; cursor: pointer; width: 40px;height: 40px;border-radius: 50px;display: inline-flex;justify-content: center;align-items: center;}
#footer .footer-content .social ul li a svg{font-size: 22px;color: #fff;}
#footer .footer-content .social ul li .youtube-icon{background-color: #b5181c} 
#footer .footer-content .social ul li .twitter-icon{background-color: #499fc2} 
#footer .footer-content .social ul li .facebook-icon{background-color: #073270} 
#footer .footer-content .social ul li .linkedin-icon{background-color: #013158} 
#footer .footer-content .social ul li a:hover{background: linear-gradient(90deg,#0DC7C9,#0074C1);}
#footer .footer-content .social .social-icons:before,
#footer .footer-content .social .social-icons:after {content: "";display: block;position: absolute;width: 599px;background-color: #0074C1;height: 1px;top: 50%;margin-top: -3px;}
#footer .footer-content .social .social-icons:before {right: 100%;}
#footer .footer-content .social .social-icons:after {left: 100%;}
#footer .footer-content  .input-ft{margin: 0px auto;max-width: 480px;}
#footer .footer-content  .input-ft .newsletter{font-size: 14px;color: #fff;}
#footer .footer-content .input-group{height: 55px;border-radius: 4px;}
#footer .footer-content .form-control{color: #fff;}
#footer .footer-content button,#footer .footer-content .form-control{background-color: transparent;border: 1px solid #8b8b92;}
#footer .footer-content .input-group-text{color: #fff;font-size: 16px;font-family: 'Outfit-Medium';padding: 0px 20px;cursor: pointer;background: transparent;border: none;}
#footer .footer-content button:hover{background: linear-gradient(90deg,#0DC7C9,#0074C1);}
#footer .footer-content button:focus,#footer .footer-content .form-control:focus{box-shadow: none;outline: none;}
#footer .footer-content .form-control::placeholder{font-size: 14px;font-family: 'Outfit-Regular';opacity: 0.4;}
#footer .footer-content .copy-right{font-size: 14px;color: #fff;font-family: 'Outfit-Regular';position: relative;z-index: 9999;}
#footer .footer-content .copy-right ul li{margin: 0 0 0 20px;}
#footer .footer-content .copy-right ul li a{color: #fff;cursor: pointer;}
#footer .footer-content .copy-right ul li a:hover{color:#0DC7C9 ;}
#footer .footer-content .copy-right  .all-right-reserved{font-size: 14px;font-family: 'Outfit-Regular';color: #fff;}
#footer .footer-content .company-logo{position: absolute;bottom: 0;right: 0;}
#footer .ft-merge{position: absolute;right: -2%;bottom: 0;width: 800px;object-fit: cover;overflow: hidden;}
#footer .ft-merge img{mix-blend-mode: difference;width: 100%;}
@media screen and (min-width: 1920px){#footer > .container{max-width: 1641px !important;}}
  