#header .navbar>.container{max-width: 87% !important;}
#header .navbar-brand img{width: 120px;}
#header .nav-wrapper{width: 100%;z-index: 9999;padding: 0px 10px;position: fixed;top: 0;left: 0; width: 100%; background: transparent linear-gradient(90deg, rgb(2, 2, 26, 0.5) 0%, rgb(11, 32, 110, 0.5) 100%) 0% 0% no-repeat padding-box;}
#header .nav-wrapper .nav-link{color: #fff;font-size: 16px;margin: 0px 18px 0 0;font-family: 'Outfit-Regular';}
#header .nav-wrapper .nav-link:hover{color: #0cc2c9;}
#header .navbar-toggler{ background: linear-gradient(90deg,#0DC7C9,#0074C1);border: none;}
#header .navbar-toggler:focus{box-shadow: none;}
#header .dropdown-toggle::after{display: none;}
#header .navbar-nav .dropdown-menu{background: #06164c;border-radius: 0px;border: none;}
#header .navbar-nav .dropdown-item {color: #fff;border-bottom: 1px solid #09205d;position: relative;}
#header .navbar-nav .dropdown-item:last-of-type {border-bottom: none;}
#header .dropdown-menu{visibility: hidden;}
#header .navbar-expand-lg .navbar-nav .dropdown-menu {position: absolute;top: 100%;margin: 0;}
#header .navbar-nav .dropdown-item:hover, #header .navbar-nav .dropdown-item:focus{color: #0DC7C9;background-image: none;background-color: transparent;}
#header .navbar-nav .dropdown-item h6{margin: 0;padding: 0;font-size: 16px;font-family: 'Outfit-Medium';font-weight: 300;}
#header .navbar-nav .dropdown-item p{margin-bottom: 7px;font-size: 12px;font-family: 'Outfit-Regular';}
#header .navbar-nav .platform-icons{width: 40px;height: 40px;border-radius: 50px;background: linear-gradient(90deg,#0DC7C9,#0074C1);padding: 7px;color: #fff;display: flex;align-items: center;justify-content: center;}
#header .navbar-nav .platform-icons a{color: #fff;}
#header .platform-info{width: calc(100% - 50px);}
/* #header > .sticky {position: fixed;top: 0;left: 0; width: 100%;animation: slide 0.3s;box-shadow: 0 20px 30px rgba(#000, 0.2);background-color: linear-gradient(90deg,rgb(2, 2, 26),rgb(11, 32, 110));;z-index: 9999;padding: 10px;} */
/**/
#header .nav-wrapper .navbar-nav li .dropdown:hover + .dropdown-menu{display: block; visibility: visible;}
#header .nav-wrapper .navbar-nav li .dropdown-menu:hover{display: block; visibility: visible;}
#header .nav-wrapper .navbar-nav li{position: relative;}
#header .nav-wrapper .navbar-nav li span{color: #fff;font-size: 16px;margin: 0px 18px 0 0;font-family: 'Outfit-Regular';padding: 0.5rem;display: block;cursor: pointer;}
#header .nav-wrapper .navbar-nav li span:hover{color: #0cc2c9;}
/*responsiveness*/
@media only screen and (max-width: 991px) {
    #header .nav-wrapper .navbar-nav li span {padding: 0.5rem 0;display: inline-block;}
}