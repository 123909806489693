.header { padding: 36px 12px 38px;}
.second-head{width: 83.34%; display: flex; align-items: center;margin: 0 23px 0 20px;}
.search-input {width: 50%;}
.connect-section {width: 50%;display: flex; justify-content: end;}
.connect-section .dropdown .connect-btn { min-height: 48px;}
.logo {text-align: center;width: 16.67%;}
.logo img{width: 130px;}
.top-icon {border: none;padding: 0%; border-radius: 12px;width: 58px;height: 58px;
/* background: linear-gradient(84.05deg,#fad96a,#fd5fcc); */
background: linear-gradient(90deg, #0DC7C9, #0074C1);
display: flex;justify-content: center;align-items: center;position: relative;color: #fff;}
.header-flex .navbar-section{display: none;}
/* .top-icon img {width: 24px;cursor: pointer;} */
.top-icon img {
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-radius: 12px;
    padding: 0;
    margin: 0;
    object-fit: cover;
    overflow: hidden;
}
.top-icon .value-span {background-color: #fc7db3;width: 15px;height: 15px;border-radius: 50%;display: flex;justify-content: center;align-items: center;font-size: 10px;position: absolute;right: 6px;top: 6px;}
/* .top-icon:active, .top-icon:focus, .top-icon:hover, .show>.top-icon{border: 1px solid #3e3f55 !important; background-color: #3e3f55 !important;} */
input {background: rgb(255 255 255 / 13%);
    border: 1px solid #a4a4a4;border-radius: 12px;padding: 12px 50px 12px 60px;color: #fff;}
.overview {margin-right: 58px;color: #fff;font-family: 'Century Gothic';font-style: normal;font-weight: bold;font-size: 24px;line-height: 36px;}
.search-bar{width: calc(100% - 168px);position: relative;}
.search-icon {position: absolute;left: 20px;top: 50%;transform: translateY(-50%);cursor: pointer;}
.close-icon {position: absolute;right: 20px;top: 50%;transform: translateY(-50%);cursor: pointer;}
/*arrow images*/
.header  .connect-btn {border: none; 
/* background: linear-gradient(86.3deg,#46bdf4 -46.4%,#2b56f5);border-radius: 12px; */
background: linear-gradient(90deg, #0DC7C9, #0074C1);
border-radius: 35px;
padding: 17px 23px;color: #FFFFFF;transition: all ease-in-out 0.3s;font-weight: 600;}
.header  .connect-btn:hover{background: linear-gradient(86.3deg,#0074C1 -46.4%,#0DC7C9); color: #fff;}
/* .connect-btn:focus{background: linear-gradient(86.3deg,#fddf7c -46.4%,#a948bf) !important;color: #FFFFFF !important;} */
.header  .btn-check:focus+.btn-primary, .btn-primary:focus{box-shadow: none !important;}
.header .arrow-images{margin-left: 10px;}
.header  .arrow-images .black-image{display: block;}
.header  .arrow-images .red-image{display: none;} 
/* .connect-btn:hover .black-image{display: none;}
.connect-btn:hover .red-image{display: block;}
.connect-btn:active .black-image{display: block;} */
/* .connect-btn:active .red-image{display: block;} */
/* .connect-btn:focus:hover .black-image{display: block;}
.connect-btn:focus:hover .red-image{display: none;} */
/**/
.header  .sidebar-icons, .sidebar-tag{display: inline-block;}
.header  .sidebar-icons .black-icon{display: block;}
.header  .sidebar-icons .red-icon{display: none;}
.header  .sidebar-tag{margin-left: 16px; transition: all ease-in-out 0.2s;font-size: 20px; line-height: 27px;font-weight: 500;}
/*hover*/
.header .navbar-light .navbar-nav .nav-link{margin: 0 0 15px;color: #7b7c8c;}
.header .nav-options a:hover{border-radius: 12px;color: #fff !important;}
.header .nav-options a:hover .sidebar-icons .black-icon{display: none;}
.header .nav-options a:hover .sidebar-icons .red-icon{display: block;}
.header .nav-options a:hover .sidebar-tag{margin-left: 21px;}
/*active*/
.header .nav-options a.active{border-radius: 12px; color: #fff !important;}
.header .nav-options a.active .sidebar-icons .black-icon{display: none;}
.header .nav-options a.active .sidebar-icons .red-icon{display: block;}
/**/
/*metamask modal*/
.modal-backdrop{background: #1A1A1A !important;}
.modal-backdrop.show{opacity: 0.9 !important;}
.btn-close {display: none;}
.modal-cross-icon{position: absolute; top: 16px; right: 16px; z-index: 99999999; }
.modal-cross-icon .cross-icon-in{font-size: 25px;color: #7b7c8c;cursor: pointer;}
.modal-cross-icon .cross-icon-in:hover{color: #fff;}
.metamask-modal .modal-header {display: inline-block !important;text-align: center;border: none !important;padding: 0 !important;}
.metamask-modal .modal-content {
/* background-color: #2e2f44; */
background: rgba(12, 44, 111,0.9);
border-radius: 12px !important;padding: 32px 32px;}
.metamask-modal .modal-head{font-family: 'Century Gothic';font-style: normal;font-weight: bold;font-size: 24px;line-height: 150%;color: #fff;}
.metamask-modal .margin-bottom{margin: 0 0 20px;display: block;}
.metamask-modal .yellow-div{background: #FDBA7B;border-radius: 12px;padding: 8px 28px;cursor: pointer; transition: all ease-in-out 0.3s;border: 1px solid transparent;}
/* .metamask-modal .yellow-div:hover {box-shadow: 2px 2px 0px #000;} */
.metamask-modal .yellow-div:hover{
    box-shadow: rgb(253 186 123 / 40%) 0px 5px 15px;border: 1px solid #fff;}
.metamask-modal .blue-div{background: #2F80ED;border-radius: 12px;padding: 14px 28px;cursor: pointer; transition: all ease-in-out 0.3s;border: 1px solid transparent;}
/* .metamask-modal .blue-div:hover {box-shadow: 2px 2px 0px #000;} */
.metamask-modal .blue-div:hover
{
box-shadow:rgb(47 128 237 / 40%) 0px 5px 15px;border: 1px solid #ffff;
}
.metamask-modal .div-tag{font-family: 'Century Gothic';font-style: normal;font-weight: bold;font-size: 20px;line-height: 130%;color: #FFFFFF;}
.metamask-modal .modal-body {padding: 0 !important;}
/* .fade.metamask-modal.modal.show {display: flex !important;justify-content: center;align-items: center;} */
/* .metamask-modal.modal.show .modal-dialog {width: 432px;} */

/* .header .metamask-modal .modal-logo {width: 35%;margin: 0 auto 30px;} */

/*dropdown wallet*/
.header .dropdown-toggle::after{display: none;}
.header .connect-btn:active{background-color: #fff !important; color: #fff !important;border: none !important;}
.header .show>.connect-btn{background-color: #fff !important; color: #fff !important;border: none !important;}
/* .connect-btn:active.connect-btn:focus .black-image{display: none;}
.connect-btn:active.connect-btn:focus .red-image{display: block;} */
.header .connect-btn .wallet-img-black img, .connect-btn .wallet-img-red img{width: 20px;}
.header .connect-btn .wallet-img-black{display: block;}
.header .connect-btn .wallet-img-red{display: none;}
/* .connect-btn:hover .wallet-img-black{display: none;}
.connect-btn:hover .wallet-img-red{display: block;} */
/* .show>.btn-primary.dropdown-toggle .wallet-img-black{display: none;}
.show>.btn-primary.dropdown-toggle .wallet-img-red{display: block;}
.connect-btn:active .wallet-img-black{display: none;}
.connect-btn:active .wallet-img-red{display: block;} */
.header .connect-btn{max-width: 200px;}
.header .wallet-img-black, .wallet-img-red {width: 20px;}
.header .wallet-address {max-width: calc(100% - 28px);overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.header .dropdown-menu.show {position: absolute !important;width: 371px;height: auto;top: 0px !important;
/* background: #2e2f44; */
background: rgba(12, 44, 111,1);
box-shadow: 0px 4px 32px rgb(0 0 0 / 8%);border-radius: 12px; padding: 20px;}
.header .dropdown-menu.show  .dropdown-item{color: #FFF;}
.header .dropdown-item.active, .dropdown-item:active,.header .dropdown-item:focus, .dropdown-item:hover {color: #fff;text-decoration: none;background: linear-gradient(90deg, #0DC7C9, #0074C1);}
/* .connect-btn:focus .wallet-img-black{display: block;}
.connect-btn:focus .wallet-img-red{display: none;}
.connect-btn:active:focus .wallet-img-black{display: none;}
.connect-btn:active:focus .wallet-img-red{display: block;} */
.dropdown-tag{font-weight: 600;font-size: 16px;line-height: 120%;color: #eaeaea;margin-left: 11px;}
.full-wallet-address {font-weight: 600;font-size: 16px;line-height: 120%;color: #fff;width: 100%;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;display: block;}
.dd-first-div{margin: 0 0 12px;}
/*grey-button*/
.grey-button{background-color: #F3F7F9;border-radius: 8px;color: #333333;border: 1px solid #F3F7F9;display: flex; align-items: center; padding: 3px 16px;transition: all ease-in-out 0.3s;}
.grey-button:hover{background: linear-gradient(90deg, #0DC7C9, #0074C1);color: #fff;border:1px solid #0074C1;}
.grey-button:hover span{color: #fff;}
.grey-button .black-icon{display: block;}
.grey-button .red-icon{display: none;}
.grey-button:hover .black-icon{display: none;}
.grey-button:hover .red-icon{display: block;}
/* .grey-button:hover{color: #2b56f5;} */
.grey-button-text{margin-left: 8px;}
input:focus{outline: none !important;}
/*notifications*/
.header .top-icon-2 .dropdown-menu.show{width: 482px !important;height: 605px !important;}
.header .top-icon-2 .noti-tag{font-weight: 600;font-size: 16px;line-height: 120%;color: #fff;}
.header .top-icon-2 .read-button{font-weight: 600;font-size: 16px;line-height: 120%;color: #2F80ED;background-color: transparent;border: none !important;cursor: pointer;}
.header .top-icon-2 .noti-section{margin: 0 0 22px;}
.header .top-icon-2 .notifications {height: calc(100% - 48px);overflow-y: auto;}
/*message modal*/
.header  .messages-modal .modal-content{background: #2e2f44;border-radius: 12px;padding: 20px 30px;}
.header  .fade.messages-modal.modal.show {display: flex !important;justify-content: center;align-items: center;}
.header  .messages-modal.modal.show .modal-dialog {width: 1021px;height: 629px; max-width: none;}
/*media quries*/
/*container + container-fluid*/
@media only screen and (max-width:1399px){
    /*modal*/
    .header  .metamask-modal .modal-head {font-size: 22px;}
    .header  .metamask-modal .div-tag {font-size: 18px;}
    .header  .metamask-modal .modal-content {padding: 26px 32px;}
    .header  .metamask-modal .yellow-div {padding: 5px 28px;}
    .header  .metamask-modal .blue-div {padding: 11px 28px;}
    .header  .metamask-modal .margin-bottom {margin: 0 0 15px;}
    /*notification*/
    .header  .top-icon-2 .dropdown-menu.show {width: 448px !important;height: 550px !important;}
}
@media only screen and (max-width:1199px){
    .header  .overview-header{display: none;}
    .header  .logo {width: 25%;}
    .header  .second-head {width: 75%;}
    .header  .search-bar {width: 100%;}
}
@media only screen and (max-width:991px){
    .header  .header-flex{flex-direction: column;}
    .header  .logo{margin: 0 0 35px;}
    .header  .second-head {width: 100%;}
    .header  .top-icon-1 {position: absolute;top: 40px;right: 30px;}
    .header  .top-icon-2 {position: absolute;top: 40px;right: 30px;}
    .header  .top-icon {background: transparent;background-color: transparent; border: none;}
    .header  .top-icon:active, .top-icon:focus, .top-icon:hover, .show>.top-icon {border: none !important;background-color: transparent !important;}
    .header  .search-input {width: 70%;}
    .header  .connect-section {width: 30%;}
    .header  .navbar{display: block; position: static !important;}
    .header  .navbar-section {position: absolute;top: 44px;left: 30px;}
    .header  .navbar-toggler {border: none !important;padding: 0;}
    .header  .navbar-toggler:focus{box-shadow: none !important;}
    .header  .navbar-collapse {position: fixed;top: 0;left: 0px;right: 0;bottom: 0;background-color: #1a1b2b;width: 100%;height: 100vh;z-index: 999;overflow: hidden;}
    .header  .nav-logo {position: absolute;bottom: 30px;left: 50%;transform: translateX(-50%);width: 140px;}
    .header  .nav-options {padding: 137px 40px 0px; display: inline-block !important;}
    .header  .cross-icon{position: absolute;top: 63px;left: 20px;}
    .header  .cross-icon .cross-icon-in{font-size: 25px;color: #7b7c8c;cursor: pointer;}
    .header  .cross-icon .cross-icon-in:hover{color: #fff;}
}
@media only screen and (max-width:767px){
    .header  .connect-section {width: 50%;}
    .header  .search-input {width: 50%;}
    /*modal*/
    /* .metamask-modal.modal.show .modal-dialog {width: 100%;} */
    .modal-logo {width: 50px;margin: 0 auto;}
    .metamask-modal .modal-head {font-size: 20px;}
    .metamask-modal .div-tag {font-size: 16px;}
    .metamask-modal .modal-content {padding: 24px 32px;}
    .metamask-modal .yellow-div {padding: 4px 28px;}
    .metamask-modal .blue-div {padding: 10px 28px;}
    .metamask-modal .margin-bottom {margin: 0 0 12px;}
}
@media only screen and (max-width:575px){
    /*notifications*/
    .header  .top-icon-2 .dropdown-menu.show {right: auto !important;top: 55px !important;left: -378px !important;}
    /**/
    .header .logo {width: 30%;}
}
/*mobile screen*/
@media only screen and (max-width:479px){
    .header  .second-head {flex-direction: column;}
    .header  .search-input {width: 100%;margin: 0 0 20px;}
    .header  .connect-section {width: 100%; justify-content: center;}
    .header  .top-icon-2 {right: 5px;}
    .header  .top-icon-1 {right: 15px;}
    .header  .navbar-section {left: 15px;}
    .header  .sidebar-tag {font-size: 18px;}
    .header  .cross-icon .cross-icon-in {font-size: 20px;}
    .header  .nav-options {padding: 90px 25px 0px;}
    .header  .cross-icon {top: 34px;}
    /*modal*/
    .header  .metamask-modal .modal-content {padding: 18px 13px;}
    .header  .metamask-modal .yellow-div {padding: 4px 20px;}
    .header  .metamask-modal .blue-div {padding: 10px 20px;}
    .header  .metamask-modal .modal-head {font-size: 16px;}
    /*dropdown*/
    /* .header .dropdown-menu.show {width: 265px;} */
    .header .dropdown-menu.show{width: 100%;}
    .header  .dropdown-buttons { flex-direction: column;}
    .header  .grey-button {margin: 5px 0px;width: 100%;justify-content: center;padding: 1px 0;}
    /* .header  .dropdown-menu.show {top: 100% !important;left: -30% !important;transform: translateX(21%) !important;} */
    /*notifications*/
    .header  .top-icon-2 .dropdown-menu.show {width: 308px !important;height: 550px !important;right: auto !important;top: 83px !important;left: -320px !important;padding: 20px 5px 20px 12px !important;}
    /**/
    .header  .logo { width: 40%;}
    .header  .navbar-section { top: 40px;}
    .header  .top-icon-1 { top: 33px;}  
}
@media only screen and (max-width:319px){
    /*modal*/
    .header .metamask-modal .div-tag {font-size: 14px;}
    /*notifications*/
    .header  .top-icon-2 .dropdown-menu.show {width: 265px !important;height: 560px !important;right: auto !important;top: 80px !important;left: -268px !important;}
}
