/*search page*/
.search-section{height: 680px; overflow-y: auto;}
.search-main-tag{font-family: 'Century Gothic';font-style: normal;font-weight: bold;font-size: 24px;line-height: 150%;display: block;color: #1A1A1A;margin: 0 0 12px;}
.no-data-img{display: block;}
.no-data-tag{font-family: 'Century Gothic';font-style: normal;font-weight: bold;font-size: 24px;line-height: 150%;color: #7b7c8c;}
.no-data-margin{margin: 0 0 94px;}
/*media quries*/
@media only screen and (max-width:767px){
    .search-main-tag {font-size: 20px;}
    .no-data-tag {font-size: 20px;}
    .no-data-img {width: 140px;margin: 0 auto;}
}
/*mobile screen*/
@media only screen and (max-width:479px){
    .search-main-tag {font-size: 18px;}
    .no-data-tag {font-size: 18px;}
}
@media only screen and (max-width:319px){
    .search-main-tag {font-size: 16px;}
    .no-data-tag {font-size: 16px;}
}