.dao-things{margin-top: -80px;}
.dao-things .dao-content{background-image: url('../../../assets/images/bg-2.png');background-repeat: no-repeat;background-size: 100% 100%;border-radius: 30px; min-height: 664px;position: relative;padding: 100px 130px 40px 130px;background-position: center;}
.dao-things .dao-content .dao-card {margin: 0 0 70px 0;}
.dao-things .dao-content .dao-card .icon-box{position: relative;max-width: 115px;max-height: 115px;padding: 20px;}
.dao-things .dao-content .dao-card .icon-box.icon-box-3{padding: 30px;}
.dao-things .dao-content .dao-card .icon-box:before {content: "";position: absolute;inset: 0;border-radius: 20px; padding: 3px; background:linear-gradient(132deg,#AAB9E5,#2248B9); -webkit-mask:linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);-webkit-mask-composite: xor;mask-composite: exclude; }
.dao-things .dao-content .dao-card-content{padding-left: 50px;color: #fff;width:calc(100% - 115px)}
.dao-things .dao-content .dao-card-content h5{font-size: 30px;font-family: 'Outfit-SemiBold';margin-bottom: 12px;}
.dao-things .dao-content .dao-card-content p{font-size: 16px;font-family: 'Outfit-Regular';}
/*responsiveness*/
@media screen and (min-width: 1920px){
    .dao-things > .container{max-width: 1641px !important;}
}
@media only screen and (max-width: 991px) {
    .dao-things {margin-top: -75px;}
}