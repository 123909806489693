.messages-tag{  font-family: 'Century Gothic';font-style: normal;font-weight: bold;font-size: 24px;line-height: 150%;color: #000000;display: block;margin: 0 0 20px;}
.messages-banner{margin: 5px 5px 20px;background-color: transparent;border-radius: 8px;padding: 10px 10px;cursor: pointer;}
.messages-banner:hover{box-shadow: 0 0 5px #000;}
.messages-banner.active{background: #F8F8F8;}
.message-img{height: 36px; width: 36px; border-radius: 50%; overflow:hidden;}
.message-img img{width: 100%; height: 100%; object-fit: cover;}
.message-data{margin-left: 12px; width: calc(100% - 48px);position: relative;padding-right: 10px;}
.message-data .message-head{margin: 0 0 5px;}
.message-data .name{font-weight: 600;font-size: 16px;line-height: 120%;color: #333333;display: block;}
.message-data .time{font-weight: normal;font-size: 14px;line-height: 120%;color: #828282;display: block;}
.message-data p{font-weight: normal;font-size: 14px;line-height: 120%;color: #333333;margin: 0;    width: 80%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
.message-dot{position: absolute;font-size: 10px;line-height: 3px;bottom: 5px;right: 15px;width: 10px;height: 10px;color: #2F80ED;display: none;}
.messages-banner.active .message-dot{display: block;}
.all-messages {overflow-y: auto;height: calc(100% - 56px);}
/* responsive */
@media only screen and (max-width:1199px){
     /*message section*/
     /* .messages-modal.modal.show .modal-dialog {width: 85%;} */
}
@media only screen and (max-width:991px){
    /*messages section*/
    /* .messages-modal.modal.show .modal-dialog {width: 90%;} */
}
@media only screen and (max-width:575px){
    /*messagesmodal*/
    .messages-modal .modal-content {padding: 20px 0px;}
}
@media only screen and (max-width:479px){
     /*messages-modal*/
    .messages-tag {font-size: 20px;}
    .message-chat-section .chat-tag {font-size: 20px;}
    .messages-modal .modal-body {padding: 22px 8px;}
    /* .messages-modal.modal.show .modal-dialog {width: 95%;} */
}