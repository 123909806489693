.community-area{position: relative;}
.community-area .comminity-bg{overflow: hidden;position: relative;display: inline-block;left: 50%;transform: translateX(-50%);}
.community-area .comminity-bg::before{overflow: hidden; mix-blend-mode: overlay; position: absolute;content: ""; top: 2%;left: 0%;width:0;height: 100vh; transition: all ease-in-out 1s;
	-moz-transition: all ease-in-out 3s;
	-webkit-transition: all ease-in-out 3s;
	-ms-transition: all ease-in-out 3s;
	-o-transition: all ease-in-out 3s;
	animation: animatedLayer  3s linear infinite alternate;
	-moz-animation:  animatedLayer 3s linear infinite alternate;
	-webkit-animation:  animatedLayer 3s linear infinite alternate;
	-ms-animation:  animatedLayer 3s linear infinite alternate;
	-o-animation:  animatedLayer 3s linear infinite alternate;}
@keyframes animatedLayer {
    0%{ width: 0;height: 100%;background-color: transparent;}
    50% {width: 100%;background-color: transparent;height: 100%;}
    70% {width: 100%;background-color: rgba(0, 0, 0,0.4);height: 100%;}
    90% {width: 100%;background-color: rgba(0, 0, 0,0.4);height: 100%;}
    97% {width: 100%;background-color: transparent;height: 100%;}
    100% {width: 100%;background-color: transparent;height: 100%;}
  }
.community-area .community-content{padding-top: 30px;}
.community-area .community-content .custom-box{position: relative; max-width: 400px;text-align: start;}
.community-area .community-content .box-area{padding: 50px 0px;position: absolute;top: 56%;left: 50%;transform: translate(-50%,-50%);}
.community-area .community-content .custom-box h4{font-size: 40px;font-family: 'Outfit-SemiBold';color: #000000;}
.community-area .community-content .custom-box  p{font-size: 22px;font-family: 'Outfit-Regular';color: #000000;font-weight: 100;}
.community-area .community-content .custom-box h4,.community-area .community-content .custom-box p{background-image: linear-gradient(-45deg, #038ac3 0%, #0bbbc8 29%, #000 67%,#001139 100%);background-size: auto auto;background-clip: border-box;background-size: 200% auto;color: #fff;background-clip: text;text-fill-color: transparent;-webkit-background-clip: text;-webkit-text-fill-color: transparent;animation: textclip 6s linear infinite;display: inline-block;}
@keyframes textclip {to {background-position: -200% center;}}
.community-area .community-content .custom-box.one{right: 50px;}
.community-area .community-content .custom-box.two{left: 120px;}
.community-area .community-content .custom-box.three{left: 70px;}
/* .comminity-bg img {min-height: 100vh;} */
@media screen and (min-width: 1920px){
	.community-area > .container{max-width: 1641px !important;}
}
/*responsive*/
@media screen and (max-width: 1919px){
	.community-area .comminity-bg {height: 940px;}
}
@media screen and (max-width: 1799px){
	
}
@media screen and (max-width: 1599px){
	.community-area .comminity-bg {height: 800px;}
}
@media screen and (max-width: 1399px){
	.community-area .comminity-bg {height: 655px;}
}
@media screen and (max-width: 1199px){

}
@media screen and (max-width: 991px){
	.community-area .comminity-bg {height: 800px;}
}
@media screen and (max-width: 767px){

}
@media screen and (max-width: 575px){
	.community-area .comminity-bg {height: auto;}
}
@media screen and (max-width: 479px){

}
@media screen and (max-width: 319px){

}