.sidebar ul li {margin: 0px 0 12px;}
.sidebar ul{list-style: none;}
.sidebar ul li a{text-decoration: none;color: #fff;padding: 16px 24px; border-radius: 12px;}
.sidebar ul li svg{color: #fff;stroke: #fff;}
.sidebar ul li a .sidebar-tag{transition: all ease-in-out 0.3s;}
/*hover*/
.sidebar ul li a:hover{
background:linear-gradient(90deg, #0DC7C9, #0074C1);color: #fff;}
/* .sidebar ul li a:hover .sidebar-icons .black-icon{display: none;}
.sidebar ul li a:hover .sidebar-icons .red-icon{display: block;} */
.sidebar ul li a:hover .sidebar-tag{margin-left: 21px;transition: all ease-in-out 0.3s;}
/*active*/
.sidebar ul li a.active{background:linear-gradient(90deg, #0DC7C9, #0074C1); color: #fff;}
/* .sidebar ul li a.active .sidebar-icons .black-icon{display: none;}
.sidebar ul li a.active .sidebar-icons .red-icon{display: block;} */
/**/
.sidebar .sidebar-icons, .sidebar .sidebar-tag{display: inline-block;}
/* .sidebar .sidebar-icons .black-icon{display: block;} */
.sidebar .sidebar-icons .red-icon{display: block;}
.sidebar .sidebar-tag{margin-left: 16px; transition: all ease-in-out 0.2s;font-size: 16px; line-height: 28px;font-weight: 500;}
.menu-icon,.menu-icon2 {display: none;}
/*media quries*/
/* container-fluid */
/*container + container-fluid*/
@media only screen and (max-width:1399px){
    .sidebar ul li a {padding: 16px 15px;}
}
@media only screen and (max-width:991px){
    /* .sidebar {display: none;} */
    .sidebar {
        position: absolute;
        left: -302px;
        z-index: 3;
        transition: all ease-in-out 0.3s;
        background-color: #0b0a2a;
        width: 0;
        padding: 100px 30px 0 30px;
        top: 0;
    }

    .show .sidebar {
        left: 0;
        width: 100%;
        height: 100%;
        top: 0;
        transition: all ease-in-out 0.3s;
        z-index: 99;
    }
    .show .menu-icon{display: none;}
    .show  .menu-icon2{display: block;float: right;z-index: 4;top: 25px;position: absolute;right: 50px;
        /* position: absolute;right: 100px; */
    }
    .menu-icon {
        display: block;
        position: absolute;
        left: 50px;
        top: 25px;
        transition: all ease-in-out 0.3s;
    }

    .show .menu-icon {
        margin: 0 0 0 198px;
        z-index: 4;
    }

    .show .sidebar-show {
        display: block;
        z-index: 3;
    }


}