.message-chat-section{position: relative;}
.message-chat-section .chat-tag{  font-family: 'Century Gothic';font-style: normal;font-weight: bold;font-size: 24px;line-height: 150%;color: #000000;display: block;margin: 0 0 20px;}
.message-chat-section .arrow{position: absolute; left: 16px; top: 5px;background-color: transparent;border: none;padding: 0;}
.message-chat-section .chat-area{background-color: #F3F7F9;border-radius: 12px;width: 100%; height: 550px;padding: 20px 16px;}
.message-chat-section .chat-area-img{width: 36px; height: 36px; overflow: hidden; border-radius: 50%;}
.message-chat-section .chat-area-img img{width: 100%; height: 100%; object-fit: cover;}
.message-chat-section .chat-area-data{margin-left: 16px; max-width: calc(100% - 52px);}
.message-chat-section .chat-area-data .name{display: block;font-weight:bold;font-size: 16px;line-height: 120%;color: #333333;}
.message-chat-section .chat-area-data .time{display: block;font-weight: normal;font-size: 14px;line-height: 120%;color: #333333;}
.message-chat-section .chat-area-data .time .green{color: #219653;font-weight: 500;}
.message-chat-section .chat-area-banner{position: relative;border-bottom: 1px solid #E0E0E0; padding-bottom: 20px;}
.message-chat-section .chat-area-banner .options{position: absolute; top: 50%; transform: translateY(-50%); right: 20px;}
.message-chat-section .options-button, 
.message-chat-section .options-button:active, 
.message-chat-section .options-button:focus, 
.message-chat-section .options-button:hover, 
.message-chat-section .show>.otions-button {background-color: transparent !important;color: #000 !important;border: none !important;padding: 0 !important;}
.message-chat-section .chat-area-heading{visibility: hidden;}
/*input section*/
.input-section {position: absolute;bottom: 0;left: 10px;right: 10px;}
.input-section input {background: #FFFFFF;box-shadow: 0px 4px 32px rgb(0 0 0 / 8%);border-radius: 12px;padding: 15px 18px;font-weight: normal;font-size: 14px;line-height: 120%;color: #333333;}
.input-section .form-area{position: relative;}
.input-section .submit-button{width: 36px;height: 36px;background-color: #219653 !important;border-radius: 8px;display: flex;justify-content: center;align-items: center;padding: 0;position: absolute;top: 50%;transform: translateY(-50%);right: 6px;}
.input-section input:focus {box-shadow: none !important; box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08) !important; border: none !important;}
.input-section .emoji-icon{position: absolute;top: 50%;transform: translateY(-50%);right: 90px; cursor: pointer;}
.input-section .pin-icon{position: absolute;top: 50%;transform: translateY(-50%);right: 60px; cursor: pointer;}
.typing-msg{position: absolute;bottom: 80px;left: 10px;}
.typing-msg .type-msg{font-style: italic;font-weight: 300;font-size: 12px;line-height: 120%;display: flex;align-items: center;color: #333333;}
/* responsive */
@media only screen and (max-width:1919px){

}
@media only screen and (max-width:1799px){

}
@media only screen and (max-width:1599px){

}
@media only screen and (max-width:1399px){

}
@media only screen and (max-width:1199px){

}
@media only screen and (max-width:991px){
    .message-chat-section .chat-area-heading {visibility: visible;}
}
@media only screen and (max-width:767px){

}
@media only screen and (max-width:575px){

}
@media only screen and (max-width:479px){

}
@media only screen and (max-width:319px){

}