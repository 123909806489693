.eco-system{padding: 100px 0px;}
.eco-system .eco-card{background-color: rgba(12, 44, 111,0.5);max-width: 528px;border-radius: 10px;padding: 30px;margin: 7px 0 20px 0;max-height: 100%;min-height: 280px;}
.eco-system .eco-card .eco-icon{width: 60px;height: 60px;overflow: hidden;object-fit: cover;}
.eco-system .eco-card .eco-icon img{width: 100%;}
.eco-system .eco-card .eco-content h6{font-size: 16px;color: #fff;font-family: 'Outfit-SemiBold';margin:0;}
.eco-system .eco-card .eco-content span{font-size: 14px;color: #0DC7C9;font-family: 'Outfit-Regular';margin:0;}
.eco-system .eco-card p{font-size: 16px;color: #fff;font-family: 'Outfit-Regular';font-weight: 100;}
.eco-system .circle-lottie-area{position: relative;z-index: 1;}
.eco-system .circle-lottie{position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);padding-top: 50px;}
.eco-system .circle-lottie svg{width: 340px !important;}
.eco-system  .launch-app-btn{position: absolute;left: 50%;transform: translateX(-50%);}
/*responsiveness*/
@media screen and (min-width: 1920px){
    .eco-system> .container{max-width: 1641px !important;}
} 
@media screen and (max-width: 1599px){
    .eco-system .heading-xl{max-width: 1000px;}
}
@media screen and (max-width: 1199px){
    .eco-system .heading-xl{max-width: 840px;}
}
@media screen and (max-width: 991px){
    .eco-system .heading-xl {max-width: 840px;opacity: 0.4;font-size: 40px;}
}
@media screen and (max-width: 767px){
    .eco-system .heading-xl {font-size: 30px;}
}
@media screen and (max-width: 575px){
    .eco-system .heading-xl {font-size: 27px;}
}
@media screen and (max-width: 479px){
    .eco-system .heading-xl {font-size: 17px;}
}
@media screen and (max-width: 319px){
    .eco-system .heading-xl {font-size: 16px;}
}