.notification-banner{margin: 0 0 20px;}
.noti-img{height: 36px; width: 36px; border-radius: 50%; overflow:hidden;}
.noti-img img{width: 100%; height: 100%; object-fit: cover;}
.noti-data{margin-left: 12px; width: calc(100% - 48px);position: relative;padding-right: 10px;}
.noti-data p{font-weight: 400;font-size: 16px;line-height: 130%;color: #333333;margin: 0;}
.noti-data strong{font-weight: 600px;}
.dot{position: absolute;font-size: 10px;line-height: 3px;bottom: 30px;right: 0;width: 10px;height: 10px;color: #2F80ED;display: none;}
.noti-data.active p{color: #2F80ED !important;}
.noti-data.active .dot{display: block;}
.notification-banner .red{color: #BD1722;}
/*responsive*/
@media only screen and (max-width:1399px){
    .noti-data p {font-size: 15px;}
    .notification-banner {margin: 0 0 12px;}
}
@media only screen and (max-width:479px){
    .noti-data p {font-size: 12px;}
}