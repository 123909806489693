.faqs{padding-top: 130px;color: #fff;background-image: url('../../../assets//images/header-bg.png');background-repeat: no-repeat;background-size: cover;background-position: center;}
.faqs .faq-content h3{font-size: 50px;font-family: 'Outfit-SemiBold';margin-bottom: 20px;}
.faqs .accordion{margin-bottom: 30px;}
.faqs .accordion .accordion-item:first-of-type .accordion-button{padding-right: 50px; font-size: 20px;font-family: 'Outfit-SemiBold';position: relative;}
.faqs .accordion-item:last-of-type .accordion-button.collapsed{background: #050d37;color: #fff;border: none;transition:  all.5s ease;}
.faqs .accordion-item:last-of-type .accordion-button.collapsed:hover{background: linear-gradient(90deg, #0DC7C9, #0074C1);}
.faqs .accordion-item{border: none;}
.faqs .accordion-body{ background: linear-gradient(90deg,rgb(2, 2, 26),rgb(11, 32, 110)); color: #fff;font-family: 'Outfit-Regular';}
.faqs .accordion-button:focus{box-shadow: none;}
.faqs .accordion-button::after{background-image: url('../../../assets//images/arrow-right.svg');animation: MoveLeft 2s ease-in-out infinite;position: absolute;animation-iteration-count: infinite;animation-direction: alternate;-webkit-animation-duration: 1s;-webkit-animation-name: MoveLeft;-webkit-animation-iteration-count: infinite;-webkit-animation-direction: alternate;-webkit-animation-timing-function: ease-in-out; animation-timing-function: ease-in-out;}
.faqs .accordion-button:not(.collapsed) {background: linear-gradient(90deg, #0DC7C9, #0074C1);color: #fff;}
.faqs  .accordion .accordion-item h2{background: #050d37;}
.faqs  .faqs-gif{position: relative;}
.faqs  .question-lottie{position: absolute;top: 0;left: 50%;transform: translateX(-50%);width: 200px;}
.faqs .accordion-item:last-of-type .accordion-collapse{border-bottom-left-radius: 0px;border-bottom-right-radius:0px ;}
@keyframes MoveLeft{from{right: 3%;}to { right: 2%;}}
@media screen and (min-width: 1920px){.faqs > .container{max-width: 1641px !important;}}