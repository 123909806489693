/*FONT FAMILIES*/
@font-face {
	font-family: 'Century Gothic';
	src: url('../src/assets/fonts/CenturyGothic-Bold.woff2') format('woff2'), url('../src/assets/fonts/CenturyGothic-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Gilroy';
	src: url('../src/assets/fonts/Gilroy-LightItalic.woff2') format('woff2'), url('../src/assets/fonts/Gilroy-LightItalic.woff') format('woff');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Gilroy';
	src: url('../src/assets/fonts/Gilroy-Medium.woff2') format('woff2'), url('../src/assets/fonts/Gilroy-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Gilroy';
	src: url('../src/assets/fonts/Gilroy-SemiBold.woff2') format('woff2'), url('../src/assets/fonts/Gilroy-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Gilroy';
	src: url('../src/assets/fonts/Gilroy-Regular.woff2') format('woff2'), url('../src/assets/fonts/Gilroy-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Outfit-Black';
	src: url('../src/assets/fonts/Outfit-Black.woff') format('woff');
}

@font-face {
	font-family: 'Outfit-Bold';
	src: url('../src/assets/fonts/Outfit-Bold.woff') format('woff');
}

@font-face {
	font-family: 'Outfit-ExtraBold';
	src: url('../src/assets/fonts/Outfit-ExtraBold.woff') format('woff');
}

@font-face {
	font-family: 'Outfit-ExtraLight';
	src: url('../src/assets/fonts/Outfit-ExtraLight.woff') format('woff');
}

@font-face {
	font-family: 'Outfit-Light';
	src: url('../src/assets/fonts/Outfit-Medium.woff') format('woff');
}

@font-face {
	font-family: 'Outfit-Medium';
	src: url('../src/assets/fonts/Outfit-Medium.woff') format('woff');
}

@font-face {
	font-family: 'Outfit-Regular';
	src: url('../src/assets/fonts/Outfit-Regular.woff') format('woff');
}

@font-face {
	font-family: 'Outfit-SemiBold';
	src: url('../src/assets/fonts/Outfit-SemiBold.woff') format('woff');
}

@font-face {
	font-family: 'Outfit-Thin';
	src: url('../src/assets/fonts/Outfit-Thin.woff') format('woff');
}


/*body*/
body {
	font-family: 'Gilroy' !important;
	margin: 0 !important;
	font-weight: normal;
}
.scrollbar::-webkit-scrollbar-thumb,  .scrollbar::-webkit-scrollbar-track,.scrollbar::-webkit-scrollbar-button{display: none !important;}
.custom-btn {
	white-space: nowrap;
	background: linear-gradient(90deg, #0DC7C9, #0074C1);
	color: #fff;
	border-radius: 35px;
	width: 135px;
	transition: all .3s ease;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
}

.custom-lg-btn {
	white-space: nowrap;
	background: linear-gradient(90deg, #0DC7C9, #0074C1);
	color: #fff;
	border-radius: 35px;
	transition: all .3s ease;
	border: none;
	padding: 17px 55px;
}

.custom-btn:focus,
.custom-lg-btn:focus {
	box-shadow: none;
	outline: none;
}

.custom-btn:hover,
.custom-lg-btn:hover {
	background: #0cc2c9;
}

.cursor-pointer {
	cursor: pointer;
}

h2 {
	margin-bottom: 0 !important;
}

.transition {
	transition: all ease-in-out 0.3s;
}

a {
	text-decoration: none;
}

textarea {
	resize: none;
}

.text-theme {
	color: #a948bf;
}

.margin-auto {
	margin-left: auto !important;
	margin-right: auto !important;
}

.warning-section {
	left: 50%;
	margin: 0 20px;
	position: fixed;
	text-align: center;
	top: 100px;
	z-index: 99;
	-o-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}

.warning-text {
    border: 1px solid #0074C1;
    border-radius: 30px;
    box-shadow: 0 0 10px #0dc7c9;
    cursor: pointer;
    display: inline-flex;
    font-size: 16px;
    margin: 0 auto;
    padding: 10px 15px;
    background: linear-gradient(90deg, #0DC7C9, #0074C1);
    border: none;
    color: #fff;
}
.box.hide-box{display: none;}
/* .vote-pool span{font-size: 22px !important;} */
.freedom-btn {
	color: #fff;
	border-radius: 12px;
	padding: 8px 23px;
	font-size: 16px;
	/* line-height:19px; */
	font-family: 'Gilroy';
	font-weight: 600;
	/* min-width: 200px; */
	min-width: 180px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.freedom-btn:disabled {
	cursor: not-allowed;
}

.freedom-solid-btn {
	/* background: linear-gradient(86.3deg, #fddf7c -46.4%, #a948bf); */
	background: linear-gradient(90deg, #0DC7C9, #0074C1);
	border: none;
	color: #fff;
	min-height: 50px;
}

.freedom-solid-btn:hover {
	color: #fff;
	background: linear-gradient(86.3deg, #0074C1 -26.4%, #0DC7C9);
}

.freedom-solid-btn:focus {
	background-color: #46bdf4 !important;
	color: #FFFFFF !important;
}

.freedom-solid-btn:disabled {
    background: #E0E0E1;
    border-color: #E0E0E1;
    color: black;
}

.freedom-solid-btn svg path {
	fill: #fff;
	transition: all ease-in-out 0.3s;
}
.ps-1.optional{color: #787aa3;}
.freedom-solid-btn:hover svg path,
.freedom-solid-btn:focus svg path {
	fill: #46bdf4;
}

.freedom-outline-btn {
	background: transparent;
	padding: 8px 23px;
	border: 2px solid #2b56f5;
	color: #fff;
	min-height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.freedom-outline-btn:hover {
	background: linear-gradient(86.3deg, #46bdf4 -46.4%, #2b56f5);
	color: #fff;
	border-color: #2b56f5;
}

.freedom-outline-btn:focus {
	background-color: transparent !important;
	color: #46bdf4 !important;
	border: 1px solid #46bdf4 !important;
}

.freedom-outline-btn:disabled {
	border-color: #E0E0E1;
	color: #E0E0E1;
	background-color: transparent;
}

/*scroll*/
html::-webkit-scrollbar {
	background-color: #1a1b2b;
	width: 8px;
}

html::-webkit-scrollbar-button {
	background-color: #1a1b2b;
}

html::-webkit-scrollbar-track {
	background-color: #1a1b2b;
}

html::-webkit-scrollbar-track-piece {
	background-color: #1a1b2b;
}

html::-webkit-scrollbar-thumb {
	background: #0DC7C9 !important;
	/* border-radius: 10px !important; */
}

html::-webkit-scrollbar-corner {
	background-color: #1a1b2b;
}

html::-webkit-resizer {
	background-color: #1a1b2b;
}


html {
	overflow-x: hidden;
	scrollbar-color:#0DC7C9 #1a1b2b;
	scrollbar-width: thin;
}
/**/
/*scroll*/
.table-responsive::-webkit-scrollbar {
	background-color: #1a1b2b;
	height: 8px;
}

.table-responsive::-webkit-scrollbar-button {
	/* background-color: #1a1b2b; */
	display: none;
}
::-webkit-scrollbar-button{display: none;}
.table-responsive::-webkit-scrollbar-track {
	background-color: #1a1b2b;
}

.table-responsive::-webkit-scrollbar-track-piece {
	background-color: #1a1b2b;
}

.table-responsive::-webkit-scrollbar-thumb {
	/* background: linear-gradient(84.05deg, #fad96a, #fd5fcc); */
	background: linear-gradient(86.3deg,#0074C1 -46.4%,#0DC7C9);
	border-radius: 10px;
}

.table-responsive::-webkit-scrollbar-corner {
	background-color: #1a1b2b;
}

.table-responsive::-webkit-resizer {
	background-color: #1a1b2b;
}

.table-responsive {
	/* scrollbar-color: #fd68c4 #1a1b2b; */
	scrollbar-width: thin;
	border-radius: 10px;
}

/*search-section*/
.search-section::-webkit-scrollbar {
	width: 5px;
}

.search-section {
	scrollbar-color: #46bdf4 transparent;
	scrollbar-width: thin;
}

.search-section::-webkit-scrollbar-thumb {
	background: linear-gradient(84.05deg, #fad96a, #fd5fcc) !important;
}

/*main-chats*/
.main-chats-sction::-webkit-scrollbar {
	width: 5px;
}

.main-chats-sction {
	scrollbar-color: #46bdf4 transparent;
	scrollbar-width: thin;
}

.main-chats-sction::-webkit-scrollbar-thumb {
	background: linear-gradient(84.05deg, #fad96a, #fd5fcc) !important;
}

/*notifications*/
.top-icon-2 .notifications::-webkit-scrollbar {
	width: 5px;
}

.top-icon-2 .notifications {
	scrollbar-color: #46bdf4 transparent;
	scrollbar-width: thin;
}

.top-icon-2 .notifications::-webkit-scrollbar-thumb {
	background: linear-gradient(84.05deg, #fad96a, #fd5fcc) !important;
}
.proposal-title{min-width: 350px;}
/*freedom card*/
.freedom-card {
	padding: 50px 40px;
	/* background: #2e2f44; */
	background: rgba(12, 44, 111,0.5);
	border-radius: 8px;
	box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);
}

.freedom-card .uploaded-img-div {
	width: 100%;
	height: 300px;
	overflow: hidden;
	border-radius: 12px;
}

.freedom-card .uploaded-img-div img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.freedom-card input.ReactTags__tagInputField {
	padding: 12px 15px;
}
.freedom-select__value-container{
	padding: 2px 10px !important;
}

/**/
.table-striped>tbody>tr:nth-of-type(n)>* {
	color: #fff;
}

/* Page Header Styles */
.page-header h2 {
	font-size: 24px;
	line-height: 28px;
	font-family: 'Century Gothic';
	color: #fff;
}

.page-header .dropdown-toggle {
	background-color: transparent;
	color: #fff;
	border-radius: 12px;
	padding: 17px 23px;
	font-size: 16px;
	line-height: 19px;
	font-family: 'Gilroy';
	font-weight: 600;
}

/* .page-header .dropdown-toggle:hover,
.page-header .show>.btn-primary.dropdown-toggle {
	background-color: #46bdf4;
	color: #fff;
	border: 1px solid #46bdf4;
} */
.page-header .dropdown-toggle:hover,
.page-header .show>.btn-primary.dropdown-toggle{
    color: #fff;
    background: linear-gradient(86.3deg, #0074C1 -26.4%, #0DC7C9);
}

.page-header .dropdown-toggle:focus {
	background-color: transparent !important;
	color: #46bdf4 !important;
	border: 1px solid #46bdf4 !important;
}

.page-header .dropdown-toggle svg,
.page-header .dropdown-toggle:focus svg,
.page-header .show>.btn-primary.dropdown-toggle svg {
	fill: #46bdf4;
}

.page-header .dropdown-toggle:hover svg {
	fill: #fff;
}

.page-header .dropdown-menu {
	/* width: 135px; */
	width: 100%;
	padding: 0;
	overflow: hidden;
	box-shadow: 0px 0px 6px rgb(225 225 225 / 15%);
	border: none;
	z-index: 2;
	border: 1px solid rgba(255, 255, 255, 0.905);
    z-index: 2;
    background: #2e2f44;
    border-radius: 12px;
}

.page-header .dropdown-menu .dropdown-item {
	padding: 12px;
	font-size: 16xp;
	line-height: 20px;
	font-family: 'Gilroy';
	color: #f8f8f8;
}

.page-header .dropdown-menu .dropdown-item:hover {
	color: #fff;
}

/* Dropdown Styles */
/* Custom Checkbox */
.right-label-checkbox {
	display: inline-block;
	position: relative;
	padding-left: 35px;
	cursor: pointer;
	font-size: 16px;
	line-height: 24px;
	font-family: 'Gilroy';
	font-weight: 500;
	color: #fff;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.right-label-checkbox a {
	color: #2f80ed;
	text-decoration: none;
}

.right-label-checkbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.right-label-checkbox .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 24px;
	width: 24px;
	border: 1px solid #BDBDBD;
	border-radius: 8px;
}

.right-label-checkbox input:checked~.checkmark {
	border-color: #fff;
}

.right-label-checkbox .checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

.right-label-checkbox input:checked~.checkmark:after {
	display: block;
}

.right-label-checkbox .checkmark:after {
	left: 8px;
	top: 3px;
	width: 6px;
	height: 13px;
	border: solid #fff;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

/**/
#walletconnect-wrapper .walletconnect-modal__base {
	background: #2e2f44;
}

#walletconnect-wrapper .walletconnect-qrcode__text {
	color: #fff;
}

/**/
.page-header .show>.btn-primary.dropdown-toggle svg {
	fill: #fff;
}

/* React Select Styles */
.freedom-select__menu {
	overflow: hidden;
}

.freedom-select__menu-list {
	padding: 0 !important;
}

.freedom-select__control--is-focused {
	border-color: #46bdf4 !important;
	box-shadow: none !important;
}

.freedom-select__option--is-selected,
.freedom-select__option--is-focused {
	/* background: linear-gradient(86.3deg, #fddf7c -46.4%, #a948bf) !important; */
	background: linear-gradient(90deg, #0DC7C9, #0074C1) !important;
	color: #fff !important;

}

.freedom-select__control {
	/* background-color: #3e3f55 !important;
	border: none !important; */
	background: rgb(255 255 255 / 13%) !important;
    border: 1px solid #a4a4a4 !important;
}

.freedom-select__single-value {
	color: #fff !important;
}
.freedom-select__control:focus-within{border: 1px solid #ffff !important;}
.freedom-select__control:focus-within svg{color: #fff !important;}
.freedom-select__menu {
	background-color: #273661 !important;
	/* background: rgba(255, 255, 255, 0.13) !important; */
	z-index: 4 !important;
}

.freedom-select__option {
	color: #fff !important;
}
.freedom-select__input-container {
    color: #ffff !important;
}
.mb-3.uploaded-img-div {
    width: 225px;
    height: 225px;
    border: 2px solid #fff;
    border-radius: 12px;
}
.profile-page .card .form-control:focus-within{border: 1px solid #ffff;}
.mb-3.uploaded-img-div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
    overflow: hidden;
}
/**/
.table {
	color: #fff;
}
.stop-watch {
    min-width: 120px !important;
}
.create-claim-btn{
	border-radius: 12px;
}
.stamp-date{
    position: absolute;
	font-size: 18px !important;
    color: white !important;
    top: 84px;
    right: 1px;
    font-size: 19px;
    transform: rotate(-31deg);
	min-width: 170px;
	text-align: center;
}
.approved-stamp{justify-content: end;}
/**/
/* .metamask-modal.membertab-modal.modal.show .modal-dialog {width: 632px;} */
/* .metamask-modal.membertab-modal.modal.show .modal-dialog {
	width: 100%
} */
p.font-weight-bold.text-white.mb-3.funds{font-size: 20px;}
.table-striped.table-bordered.table-hover tbody,
td,
tfoot,
th,
thead,
tr {
	border-style: none !important;
	text-transform: capitalize;
}
.accordion .table-striped>tbody>tr td:first-child{min-width: 300px;text-align: left;}
.accordion .table-striped>tbody>tr td,.table-striped>thead th{text-align: center;}
/**/
.profile-page .card {
	/* background-color: #2e2f44; */
	background-color: rgba(12, 44, 111,0.5);
	color: #fff;
}
.community-detail .form-group .form-control:focus{box-shadow: none;}
.profile-page .card .author {
	width: 200px;
	height: 200px;
	margin: 0 0 30px;
	border-radius: 100%;
	overflow: hidden;
	position: relative;
	/* border: 8px solid #fff; */
	border: 8px solid #038cc3;
}

.profile-page .card .author img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.profile-page .card .form-control {
	/* background: #1a1b2b;
	border: none; */
	background: rgb(255 255 255 / 13%);
    border: 1px solid #a4a4a4;
	height: 55px;
	color: #fff;
	border-radius: 12px;
}

.profile-page .card .form-control:focus {
	box-shadow: none;
	outline: none;
}

.profile-page .edit-img {
	transition: all ease-in-out 0.3s;
	margin: 0 0 10px;
	position: absolute;
	bottom: 24px;
	right: 20px;
	z-index: 1;
	font-size: 30px;
}

.profile-page .edit-img:hover {
	color: #2b56f5;
}
.proposal-listing .freedom-outline-btn{
	min-height: 50px;
	}
.freedom-select__placeholder{
	color: #d6d7e0 !important;
	font-size: 13px;
}
.contenty .approved-stamp{position: absolute;right: 38px;}
/* Tags Styles */
.ReactTags__tags .ReactTags__tag {
	display: inline-block;
	vertical-align: top;
	padding: 5px;
	background: #3e3f55;
	border-radius: 7px;
	margin: 0 10px 10px 0;
	position: relative;
}
#disable-subtype {
	background: transparent	 !important;
    /* background-color: transparent !important; */
    color: #fff !important;
    cursor: not-allowed !important;
    opacity: 0.5 !important;
	font-size: inherit !important;
	margin: 0%;
	pointer-events: none;
	padding: 8px 12px;
    display: block;
	cursor: not-allowed;
}
.freedom-select__option--is-disabled{padding: 0% !important;cursor: not-allowed !important;}
.proposal-form div#react-select-15-option-0,.proposal-form div#react-select-15-option-3,.proposal-form div#react-select-15-option-9,.proposal-form div#react-select-15-option-11,.proposal-form div#react-select-15-option-15,.proposal-form div#react-select-15-option-17{font-weight: bold !important;background-color: #212236 !important;font-size: 20px;color: #fff !important;}
#disable-style{
	background-color: #212236 !important;
	color: #fff !important;
	cursor: not-allowed !important;
	font-weight: bold !important;
	padding: 8px 12px;
    display: block;
    /* background-color: #151818 !important;
    font-size: 20px !important;
    color: #fff !important; */
}

.ReactTags__tags .ReactTags__remove {
	width: 16px;
	height: 16px;
	position: absolute;
	top: -7px;
	right: -9px;
	background: #2b56f5;
	border: none;
	border-radius: 100%;
	color: #000;
	font-size: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
}

/*input file button*/
.input-file-btn-holder {
	position: relative;
	margin: 0 auto 5px;
	border-radius: 12px;
	overflow: hidden;
	width: 160px;
	height: 50px;
}

.input-file-btn-holder input {
	width: 100%;
	height: 100%;
	display: block;
	font-size: 0;
	opacity: 0;
	position: relative;
	z-index: 1;
	cursor: pointer;
}

.input-file-btn-holder .input-file-btn {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background: linear-gradient(86.3deg, #fddf7c -46.4%, #a948bf);
	color: #fff;
	font-size: 14px;
	line-height: 18px;
	font-weight: 700;
	display: flex;
	justify-content: center;
	align-items: center;
}

/**/
.main-wrapper {
	min-height: 100vh;
	position: relative;
	overflow: hidden;
}
.content ul li {
    color: #fff;
}
.main-page {
	min-height: calc(100vh - 310px);
}
.select-status
   { min-height: 48px;
    background: #3e3f55;
    border: 1px solid #3e3f55;
    border-radius: 12px;
    color: #fff;}

.image-holder {
	width: 400px;
	height: 400px;
	border-radius: 12px;
	border: 1px solid #fff;
}

.image-holder img {
	width: 100%;
	height: 100%;
	object-fit: fill;
	overflow: hidden;
	border-radius: 12px;
}

.main-body .card .card-body strong {
	text-transform: capitalize;
}

.modal-dialog h5.mb-5.text-white {
	color: #fff;
	font-family: 'Gilroy';
	font-weight: bold;
	font-size: 24px;
}
.input-holder input:focus{border: 1px solid #fff;}
.page-header .dropdown-menu {
	/* transform: translate(-11px, 62px) !important; */
	/* transform: translate(-11px, 70px) !important; */
}

span.copyright.margin-top {
	display: flex;
	justify-content: center;
	align-items: center;
}

.input-holder input {
	width: 100%;
}
/* 
.cover-file-format{margin-top: -20px;} */
.add-btn {
	color: #fff;
	background: linear-gradient(86.3deg, #a948bf -26.4%, #fddf7c);
	min-height: 46px;
	border: none;
	border-radius: 12px;
    padding: 0px 23px;
}

/* input:-internal-autofill-selected{
background: rgb(255 255 255 / 13%) !important;
border: 1px solid #a4a4a4;
} */
/*media query*/
@media (max-width: 1900px) {
	.image-holder {
		width: 100%;
	}
}

@media (min-width: 1400px) {
	.container {
		max-width: 1320px !important;
	}

}

@media (max-width: 1367px) {

	/* .metamask-modal.membertab-modal.modal.show .modal-dialog {padding:100px 0 0;} */
	/* .image-holder {
		width: 100%;
		height: auto;
	} */
}

@media only screen and (max-width:767px) {
	.page-header .btns-holder {
		width: 100%;
		justify-content: space-around;
		margin-bottom: 20px;
	}
	.proposal-listing .poolList-1 p, .vote-pool p{
		font-size: 13px;
	}
	.page-header .btns-holder>* {
		width: 48%;
		margin: 0;
		text-align: center;
	}
	.full-loader .loader {
		width: 200px;
		height: 200px;}
}
@media only screen and (max-width:687px) {
	/* .cover-file-format{margin-top: 0px;} */
.contenty .approved-stamp{position: static;right: auto;width: 100%;}
/* .stamp-date {
    z-index: 4;
    position: absolute;
    top: 56px;
    left: 0;} */

		.stamp-date{top: 122px;right: -7px;}
	
}
@media only screen and (max-width:570px) {
	/* .stamp-date{top: 125px;right: -24px;font-size: 14px !important;} */
}
@media only screen and (max-width:575px) {
	.proposal-title{min-width: auto;}
	.approved-stamp{justify-content: start;}
	.page-header .btns-holder>* {width: 100%;margin: 0 0 15px;min-width: 100%;padding: 0;}
	.image-holder {width: 100%;height: auto;}
	.full-loader .loader {width: 180px;height: 180px;}
	.explore-communities .community-search-form .search-form {padding: 0;}
	.freedom-outline-btn,.freedom-solid-btn {
		/* background: transparent; */
		/* width: 100%; */
		padding: 8px 16px;}
		.main-body .top-heading{display: block;margin-bottom: 12px;}
		.stamp-date{top: 144px;right: -24px;font-size: 14px !important;}
}

@media only screen and (max-width:479px) {
	.logo-file-format{margin-top: -30px;}
	.page-header h2 {
		font-size: 18px;
		line-height: 22px;
		margin-bottom: 10px !important;
	}

	.freedom-card {
		padding: 50px 12px;
	}

	.freedom-btn {
		font-size: 12px;
		/* min-width: 154px; */
		/* width: 100%; */
		margin: 0 auto;
		min-width: auto;
		/* min-height: 100%; */
	}
	 .freedom-btn {
		width: auto;
		padding: 0px 38px;
	}
	.dropdown {
		margin-right: 4px !important;
	}

	/**/
	.profile-page .update-button {
		width: 100%;
	}

	.right-label-checkbox {
		font-size: 14px;
	}
	.freedom-outline-btn {
		background: transparent;
		/* padding: 8px 16px; */
	}
	
}
@media only screen and (max-width:380px) {
	.stamp-date {
		top: 144px;}
}
@media only screen and (max-width:360px) {
	.page-header h2 {
		font-size: 14px;
		line-height: 18px;
	}
	.explore-communities{margin: 0%;}
	

	/* .profile-page .card .author{width: 100%;} */
	.profile-page .card .author {
		width: 180px;
		height: 180px;
	}
	.mb-3.uploaded-img-div {
		width: 125px;
		height: 125px;}
}

/* @media only screen and (max-width:320px) {
	.community-item.mb-4.d-flex{
	margin: 0 20px 0 0px;
}} */
@media only screen and (max-width:280px) {
	.page-header h2 {
		font-size: 12px;
		line-height: 16px;
	}
	.profile-page .card .author {
		width: 160px;
		height: 160px;}
	.profile-page .freedom-btn {
		min-width: 150px;
	}
}
/* otp verify  */
.otp-requirements .verify .custom-btn{position: relative; display: flex;align-items: center;justify-content: space-between;width: 145px;text-align: center;padding: 0px 20px;}
.otp-requirements .code-block.code-error input{border-color: red;}
.otp-requirements .verify .custom-btn .loader{position: absolute;right: 5%;}
/* ||  responsive  || */
@media only screen and (max-width: 1919px) {
	.eco-system .eco-card{min-height: 325px;}
	/**/
	
}
@media only screen and (max-width: 1799px) {
	.community-area .community-content .custom-box h4 {font-size: 30px;}
	.community-area .community-content .custom-box p {font-size: 19px;}
    .get-involved .involved-content .involved-card-area .involved-card{max-width: 180px;max-height: 180px;}
    .get-involved .involved-content .involved-card-area .involved-card p {font-size: 14px;}
    .banner .animated-hand .globe-1{left: 40%;top: 26%;}
    .banner .animated-hand .globe-2{left: 65%;top: 45%;}
    .banner .animated-hand .globe-3{right: -14%;top: 35%;}
    .banner .animated-hand .globe-4{right: -4%;}
    .banner .animated-hand .globe-5{ bottom: 26%;}
	.get-involved .involved-content .timeline {height: 450px;}
    .dao-things .dao-content .dao-card-content h5 {font-size: 24px;}
    .dao-things .dao-content .dao-card-content p {font-size: 14px;}
	.mission-page .mission-content p {font-size: 20px;}
	.community-area .community-content .custom-box.three{top: 30px;}
	/**/
	.community-area .community-content .custom-box.one {right: 20px;}
	.community-area .community-content .custom-box.two {left: 128px;}
	.community-area .community-content .custom-box.three {left: 90px;}
}
@media only screen and (max-width: 1599px) {
	.banner .animated-hand .grap {bottom: 5%;left: 10%;}
	.banner .animated-hand .globe-3 {right: 0%;top: 29%;}
	/* .banner .animated-hand .globe-4 {bottom: 24%;} */
	.banner .outer-circle {left: 0;}
	.banner .animated-section .tile-image-1 {left: 5%;}
	.banner .animated-section .tile-image-2 {left: 31%;}
	.banner .animated-section .tile-image-3 {left: 38%;}
	.banner .animated-section .tile-image-4 {right: 15%;bottom: -22%;}
	.banner .animated-hand .hand-image img {width: 600px;}
	.get-involved .involved-content .timeline::after{top: 13%;}
	.banner .grap h3{font-size: 85px;}
	.custom-box-btn {margin-bottom: 40px;}
	.banner .content h3 {font-size: 70px;}
	.dao-things .dao-content .dao-card {margin: 0 0 110px 0;}
	.dao-things .dao-content .dao-card-content h5 {font-size: 22px;}
	.community-area .community-content .custom-box.two {left: 100px;}
	.community-area .community-content .custom-box.three {left: 60px;}
	.community-area .community-content .custom-box {max-width: 330px;}
	.community-area .community-content .custom-box h4 {font-size: 30px;}
    .community-area .community-content .custom-box p{font-size: 17px;}
    .banner h1{font-size: 90px;}
    .custom-heading{font-size: 40px;}
	.heading-xl {font-size: 45px;}
    .proportional-area .proportional-card .proportional-title {font-size: 22px;}
    .proportional-area .proportional-card .proportional-value {font-size: 35px;}
}
@media only screen and (max-width: 1399px) {
	.muto-guide .guide-content p{font-size: 14px;}
	.muto-guide .guide-content h2 {font-size: 30px;}
	.muto-guide .guide-content h5 {font-size: 22px;}
	.muto-guide .guide-content h6{font-size: 20px;}
	.eco-system .eco-card{min-height: 350px;}
	.get-involved .involved-content .timeline::after{top: 14%;}
	.get-involved .involved-content .timeline {height: 410px;}
	.comminity-bg img{min-height: 100vh;}
	.community-area .community-content .custom-box {max-width: 300px;}
	.community-area .community-content .custom-box h4 {font-size: 22px;}
	.community-area .community-content .custom-box p {font-size: 14px;}
	.banner .animated-hand .grap{left: 0;}
	.banner .animated-hand .hand-image img {width: 475px;}
	.banner .animated-hand .globe-1{left: 30%;}
	.banner .animated-hand .globe-2{left: 52%;}
	.banner .animated-hand .globe-4{right: 10%;}
	.dao-things .dao-content .dao-card {margin: 0 0 120px 0;}
	.banner .grap h3{font-size: 70px;line-height: 80px;}
	.banner .content h3 {font-size: 60px;}
	.banner .content p{font-size: 18px;}
	.get-involved .involved-content .involved-card-area .involved-card{margin: 12px auto;}
	.get-involved .involved-content .involved-card-area .involved-card{width: 160px;height: 160px;padding-right: 10px;}
	.get-involved .involved-content .involved-card-area .involved-card h4{font-size: 15px;}
	.get-involved .involved-content .involved-card-area .involved-card p{font-size: 13px;}
	.custom-heading {font-size: 30px;}
    .heading-xl{font-size: 50px;}
    .dao-things .dao-content .dao-card-content h5 {font-size: 25px;}
    .dao-things .dao-content .dao-card-content p{font-size: 12px;}
	.mission-page .mission-content p {font-size: 18px;}
	.mission-page .mission-content h3 {font-size: 50px;}
	/**/
	.banner .animated-section .tile-image-4 {right: 7%;}
	.banner .groovy-lottie {width: 80%;}
	/**/
	.community-area .community-content .custom-box.one {right: 5px;}
	.community-area .community-content .custom-box.three {left: 85px;}
	/**/
	.comminity-bg img {min-height: initial;}
	.community-area .community-content .custom-box.two {left: 77px;}
}
@media only screen and (max-width: 1366px) and (min-width: 1366px) {
	.banner{padding-top: 50px;margin-bottom: 100px;}
	.banner .animated-section .tile-image-1 {left: 10%;z-index: 999;}
	.banner .animated-section .tile-image-3 {left: 34%;bottom: -15%;}
	.banner .animated-section .tile-image-4 {right: 26%;bottom: -5%;}
	.banner .animated-section .tile-image-1 img{width: 150px;}
	.banner .animated-section .tile-image-2 img{width: 90px;}
	.banner .animated-section .tile-image-3 img{width: 90px;}
	.banner .animated-section .tile-image-4 img{width: 150px;}
	.community-area .community-content .custom-box.three{top: 30px;}
}
@media only screen and (max-width: 1199px) {
	.banner .animated-section .tile-image-1 {left: 20%;}
	.banner .animated-section .tile-image-4 {right: 16%;}
	.banner .animated-section .tile-image-1 img{width: 150px;}
	.banner .animated-section .tile-image-2 img{width: 90px;}
	.banner .animated-section .tile-image-3 img{width: 90px;}
	.banner .animated-section .tile-image-4 img{width: 150px;}
	.proportional-area .proportional-card .content{top: 60%; left: 50%;transform: translate(-50%,-50%);}
	.proportional-area .proportional-card{margin: 35px auto;}
	.get-involved .involved-map .map-image{position: relative;right: unset;}
	.get-involved .involved-content{margin-bottom: 100px;}
	.get-involved .involved-content .timeline::after{top: 10%;}
	.get-involved .involved-content .timeline {height: 595px;}
	.comminity-bg img{object-fit: cover;}
	.heading-xl {font-size: 40px;}
	.eco-system .eco-card{min-height: 424px;}
	.dao-things .dao-content{ padding: 100px 60px 0 60px;}
	.dao-things .dao-content .dao-card {margin: 0 0 90px 0;}
	.mission-page .mission-content p {font-size: 16px;}
	.custom-xl-btn{padding: 16px 45px;}
	.faqs .accordion .accordion-item:first-of-type .accordion-button{font-size: 15px;}
}
@media only screen and (max-width: 991px) {
	#header .nav-wrapper{position: absolute;}
	.get-involved{margin-bottom: 20px;}
	#header .custom-btn{margin: 10px 0 10px 0;}
	.faqs .faqs-gif{padding-top:80px;}
	.muto-guide .guide-content h6::before{width: 10px;height: 10px;left: -2%;}
	.faqs .question-lottie{top: 32%;transform: translate(-50%,-50%);}
	.muto-guide .guides-lottie{width: 100%;}
	.muto-guide .guides-lottie > div{padding: unset !important;}
	.get-involved .involved-content .timeline::after{top: 15%;}
	.eco-system .eco-card{min-height: 380px;}
	.get-involved .involved-content .timeline {height: 410px;}
	.banner{margin-bottom: 150px;}
	.banner h1 {font-size: 50px;}
	.get-involved{padding-top: 58px;}
	.eco-system {padding: 35px 0px;}
	.dao-things .dao-content{background-size: cover;}
	.dao-things .dao-content {padding: 100px 50px 30px 50px;}
	.heading-xl {font-size: 25px;}
	.get-involved .involved-map {margin-bottom: 70px;}
	.banner .animated-hand{display: none;}
	.mission-page .mission-content{text-align: center;}
	.mission-page .rocket-image{padding-top: 50px;}
	/**/
	.banner .animated-section .tile-image-4 {right: 28%;}
	.banner .animated-section .tile-image-3 {left: 41%;bottom: -35%;}
}
@media only screen and (max-width: 767px) {
	.get-involved .involved-content .timeline::after{top: 10%;}
	.get-involved .involved-content .timeline {height: 600px;}
	.comminity-bg img{min-height: 60vh;}
	.community-area .community-content .custom-box{text-align: center;max-width: 100%;}
	.community-area .community-content .custom-box.one{right: unset;}
	.community-area .community-content .custom-box
	.banner h1 {font-size: 50px;}
	.eco-system .eco-card{min-height: 290px;}
	.dao-things .dao-content .dao-card-content{width: 100%;}
	/**/
	.banner .animated-section .tile-image-4 {right: 19%;}
	/**/
	#footer .footer-content .copy-right{flex-direction: column;}
	#footer {padding-bottom: 20px;}
}
@media only screen and (max-width: 575px) {
	.faqs .question-lottie{width: 50px;}
	.faqs .question-lottie{top: 50%;transform: translate(-50%,-50%);}
	.muto-guide .guide-content h2 {font-size: 17px;}
	.muto-guide .guide-content h5 {font-size: 18px;}
	.muto-guide .guide-content h6::before {left: -6%;height: 10px;width: 10px;}
	.proportional-area .proportional-card{max-width: 200px;}
	.proportional-area .proportional-card .proportional-title{font-size: 20px;}
	.proportional-area .proportional-card .proportional-value {font-size: 30px;}
	.get-involved .involved-content .timeline {display: none;}
	.custom-box-btn{display: flex;align-items: center;justify-content: center;}
	/* .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
		--bs-gutter-x: 1.5rem;} */
	.comminity-bg img{display: none;}
	.community-area .community-content .box-area{border-radius: 15px; background-color: #fff;position: inherit;transform: none;padding: 30px 35px;margin: 0px auto;}
	.community-area .community-content .custom-box.two {left: 0;}
	.community-area .community-content .custom-box.three {left: 0;}
	.community-area .community-content .custom-box h4 {font-size: 18px;}
	.community-area .community-content .custom-box p {font-size: 12px;}
	.banner .animated-hand .hand-image img{width: 100%;margin-left: unset;}
	.banner .grap h3 {font-size: 35px;}
	.banner .animated-section .tile-image-4 {bottom: -76%;}
	.banner .content p {font-size: 14px;}
	.banner .content h3{font-size: 50px;text-align: center;}
	.banner h1 {font-size: 40px;}
	.banner .outer-circle {left: 5%;}
	.banner .outer-circle img {width: 70px;}
	.banner .inner-circle img {width: 35px;}
	.banner .animated-section .tile-image-3 {bottom: -85%;}
	.get-involved .involved-content .involved-card-area {padding-left: unset;}
	.get-involved .involved-content .custom-heading,.get-involved .involved-content .custom-paragraph{text-align: center;}
	.dao-things .dao-content .dao-card{text-align: center;margin:  0 0 20px 0;flex-wrap: wrap;}
	.dao-things .dao-content .dao-card-content{padding-left: unset;}
	.dao-things .dao-content {padding: 100px 40px;}
	.dao-things .dao-content .dao-card .icon-box{margin: 20px auto;}
	.dao-things .dao-content .dao-card-content h5 {font-size: 16px;}
	.custom-heading {font-size: 18px;/* white-space: nowrap; */}
	.custom-paragraph {font-size: 25px;}
	#footer .footer-content .copy-right {font-size: 13px;}
	#footer .footer-content .social ul li{margin: 0 0 0 9px;}
	.eco-system .eco-card p {font-size: 12px;}
	#footer .footer-content .social .social-icons {padding: 0px 35px;}
	#footer .footer-content .social ul li a{width: 30px;height: 30px;}
	#footer .footer-content .social ul li a svg{font-size: 15px;}
	.mission-page .mission-content{padding-top: 20px;}
	.mission-page .mission-content h3 {font-size: 40px;}	
	.mission-page .mission-content p {font-size: 14px;}
	.get-involved .involved-content .involved-card-area .involved-card{height: 100%;min-height: 170px;}
	/**/
	.banner .animated-section .tile-image-4 {right: 12%;}
	.banner .content p {padding-left: 10px;}
	.dao-things {margin-top: -20px;}
	/**/
	.proportional-area .proportional-card .content {text-align: center;}
}
@media only screen and (max-width: 479px) {
	.proportional-area .proportional-card {min-height: 250px;}
	.proportional-area .proportional-card .proportional-title {font-size: 15px;}
	/**/
	.banner .animated-section .tile-image-4 {right: 12%;}
	.banner .animated-section .tile-image-1 {left: 2%;}
	.banner .animated-section .tile-image-4 {bottom: -62%;right: -2%;}
	.banner h1 {font-size: 32px;}
	.banner .outer-circle {left: -7%;}
	.banner .groovy-lottie {width: 100%;}
	/**/
	.get-involved .involved-content .involved-card-area .involved-card {height: 100%;min-height: 150px;width: 150px;}
	.proportional-area .proportional-card .proportional-value {font-size: 28px;}
	.get-involved .involved-content .involved-card-area .involved-card h4 {top: 60%;}
	/**/
	#header .navbar>.container {max-width: 100% !important;}
	#header .navbar-nav .dropdown-item {white-space: break-spaces;}
}
@media only screen and (max-width: 375px) {
	.proportional-area .proportional-card .proportional-title {font-size: 12px;}
	.proportional-area .proportional-card img {width: 40px;}
}
@media only screen and (max-width: 320px) {
}
@media only screen and (max-width: 319px) {
	.banner .animated-section .tile-image-4 img {width: 124px;}
	#footer .footer-content .copy-right ul li {margin: 0 0 0 10px;}
	.banner h1 {font-size: 30px;}
}


