.get-involved {padding-top: 120px;margin-bottom: 100px;}
.get-involved .involved-content{color: #fff;}
.get-involved .involved-content .timeline{position: relative;z-index: 999;height: 485px;display: flex;flex-direction: column;justify-content: space-between;}
.get-involved .involved-content .timeline li{z-index: 999;overflow: hidden;}
.get-involved .involved-content .timeline::after{ position:absolute;content: "";width: 3px;bottom: 2%;background-color: #fff;top: 12%;left: 50%;transform: translateX(-50%);-moz-transition: all ease-in-out 3s;-webkit-transition: all ease-in-out 1s;-ms-transition: all ease-in-out 1s;-o-transition: all ease-in-out 1s;animation: animation  1s linear infinite alternate;-moz-animation:  animation 1s linear infinite alternate;-webkit-animation:  animation 1s linear infinite alternate;-ms-animation:  animation 1s linear infinite alternate;-o-animation:  animation 1s linear infinite alternate;}
@keyframes animation {
    0%{ background: #fff;}
    100% {background: linear-gradient(132deg, #AAB9E5 0%, #2248B9 100%) 0% 0% no-repeat padding-box;}
}
.get-involved .involved-content .ellipse-image:last-child::after{height: 0px;margin-bottom: 0px;}
.get-involved .involved-content  .involved-card-area{padding-left: 100px;}
.get-involved .involved-content .involved-card-area .involved-card{ position: relative; max-width: 200px;max-height: 200px;height: 200px;width:200px;margin: 0 30px 30px 0;padding: 15px 0 0 25px;}
.involved-card:before {content: "";position: absolute;inset: 0;border-radius: 20px; padding: 3px; background:linear-gradient(132deg,#AAB9E5,#2248B9); -webkit-mask:linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);-webkit-mask-composite: xor;mask-composite: exclude; }
.get-involved .involved-content .involved-card-area .involved-card:hover{background: linear-gradient(132deg, #AAB9E5 0%, #2248B9 100%) 0% 0% no-repeat padding-box;-webkit-mask-composite: unset;border-radius: 20px;}
.get-involved .involved-content .involved-card-area .involved-card h4{font-size: 25px;font-family: 'Outfit-Bold';margin-bottom: 15px;position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);}
.get-involved .involved-content .involved-card-area .involved-card p{font-size: 16px;font-family: 'Outfit-Regular';font-weight: 300;}
.get-involved .involved-content .involved-card-area .involved-card .involve-icon img{width: 40px;background: #fff; border-radius: 50px;padding: 5px;}
.get-involved .involved-map{position: relative;}
.get-involved .map-video{mix-blend-mode: color-dodge;display: inline-block;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);overflow: hidden;}
.get-involved .map-video video{mix-blend-mode: color-dodge;}
.get-involved .global-map{overflow: hidden;}
.get-involved .involved-map .map-image {    position: relative;right: -45%;z-index: 5;overflow: hidden;border-radius: 100%;}
.get-involved .involved-map .shaded-dot img{mix-blend-mode: color-dodge;left:12%;position: absolute; top:0%;}
/*responsiveness*/
@media screen and (min-width: 1920px){
    .get-involved > .container{max-width: 1641px !important;}
}
@media only screen and (max-width: 1919px){
    .get-involved .involved-map .shaded-dot img {left: 0%;}
    .get-involved .involved-content .involved-card-area {padding-left: 45px;}
}
@media only screen and (max-width: 1399px){
    .get-involved .involved-map .shaded-dot img {left: -8%;}
}
@media only screen and (max-width: 1199px){
    .get-involved .involved-map .shaded-dot img {left: -44%;top: -26%;}
    .get-involved .involved-content .involved-card-area {padding-left: 60px;}
}
@media only screen and (max-width: 991px){
    .get-involved .involved-map .shaded-dot img {left: -19%;top: -17%;}
}
@media only screen and (max-width: 767px){
    .get-involved .involved-map .shaded-dot img {left: -33%;}
}
@media only screen and (max-width: 575px){
    .get-involved .involved-map .shaded-dot img {left: -40%;}
}
@media only screen and (max-width: 479px){
    .get-involved .involved-map .shaded-dot img {top: -30%;}
}