.chat-section {background: #FFFFFF;box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);border-radius: 8px;padding: 17px 15px 35px 15px;height: 913px;}
.chat-section h2 {font-style: normal;font-weight: 600;font-size: 18px;}
.chat-section .card {border-top: none;border-left: none;border-right: none;padding: 18px 0 0 0;}
.chat-section .card ul {align-items: center;}
.chat-section strong {font-style: normal;font-weight: 600;font-size: 16px;}
.chat-section p {font-style: normal;font-weight: normal;font-size: 16px;margin: 0%;}
.time {color: #BDBDBD;font-style: normal;font-weight: normal;font-size: 12px;}
.sub-heading{position: relative;}
.sub-heading::before {position: absolute;background: #BD1722;border: 2px solid #BD1722;content: '';height: 100%;left: 5px;}
.sub-heading strong{color: #333333;font-weight: 600;font-style: normal;font-size: 16px;}
.main-chats-head {height: 30px;}
.main-chats-sction {max-height: calc(100% - 30px);overflow-y: auto; padding-right: 15px;}
svg.svg-inline--fa.fa-ellipsis-vertical {font-size: 12px;}
/*media quries*/
/* container-fluid */
@media only screen and (max-width:1799px){

}
@media only screen and (max-width:1599px){
    
}
/*container + container-fluid*/
@media only screen and (max-width:1399px){

}
@media only screen and (max-width:1199px){

}
@media only screen and (max-width:991px){
  
}
@media only screen and (max-width:767px){

}
@media only screen and (max-width:575px){

}
/*mobile screen*/
@media only screen and (max-width:479px){
    .chat-section h2 {font-size: 15px;}
    
}
@media only screen and (max-width:319px){
    .chat-section h2 {font-size: 13px;}

}