.muto-guide{padding-top: 100px;color: #fff;background-image: url('../../../assets//images/header-bg.png');background-repeat: no-repeat;background-size: cover;}
.muto-guide .guide-content{padding: 50px 0px;}
.muto-guide .guide-content .overlay{padding: 20px 50px; background-image: linear-gradient(to right, #0DC7C9 , #0074C1);border-radius: 20px;max-width: 1000px; margin: 0px auto;}
.muto-guide .guide-content h1{font-size: 70px;font-family: 'Outfit-SemiBold';text-align: center;}
.muto-guide .guide-content h2{font-size: 50px;font-family: 'Outfit-SemiBold';position: relative;display: inline-flex;align-items: center;justify-content: center;}
.muto-guide .guide-content h5{font-size: 30px;font-family: 'Outfit-SemiBold';margin-bottom: 10px;position: relative;display:block;}
.muto-guide .guide-content h6{font-size: 25px;font-family: 'Outfit-SemiBold';padding: 10px 0 10px 0;position: relative;}
.muto-guide .guide-content h6::before{position: absolute;content: "";width: 15px;height: 15px;border-radius: 50px;left: -4%;top: 50%;transform: translateY(-50%);background: linear-gradient(90deg, #0DC7C9, #0074C1);animation: scale 2s linear infinite;}
@keyframes scale {0% {scale: 0.3;}50% {scale: 1;}}
@keyframes moveLeft {0% {width: 1%;}50% {width: 100%;}}
.muto-guide ul{list-style: none;}
.muto-guide ul li::before {content: "\2022";color: #0DC7C9;font-weight: bold;display: inline-block; width: 1em;margin-left: -1em;}
.muto-guide .guides-lottie{margin: 0px auto;text-align: center;width:100%;padding: 30px 50px 0px 50px;}
.muto-guide .guide-content p{font-size: 17px;	font-family: 'Outfit-Light';font-weight: 100;margin-bottom: 20px;}
@media screen and (min-width: 1920px){.muto-guide > .container{max-width: 1641px !important;}}