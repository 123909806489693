/*search comp*/
.search-banner{background: #2e2f44;border: 1px solid #3e3f55;border-radius: 8px;width: 49%; padding: 16px 16px 20px;margin: 0 0 12px;}
.search-img{width: 97px; height: 75px;border-radius: 4px; overflow: hidden;}
.search-img img{width: 100%; height: 100%; object-fit: contain;}
.search-data{width: calc(100% - 113px); margin-left: 16px;}
.search-data span{font-weight: 500;font-size: 12px;line-height: 180%;display: block;color: #fff; margin: 0 0 3px;}
.search-data h2{font-weight: 600;font-size: 18px;line-height: 100%;color: #2F80ED; margin: 0 0 5px !important;}
.search-data p{font-weight: normal;font-size: 14px;line-height: 130%;color: #fff; margin: 0 ;}
/*media quires*/
@media only screen and (max-width: 991px){
    .search-banner{width: 49%;}
}
@media only screen and (max-width:767px){
    .search-banner{width: 100%;}
    .search-data h2 {font-size: 16px;}
    .search-data p {font-size: 12px;}
}
@media only screen and (max-width:575px){
    .search-img {width: 75px;}
    .search-data {width: calc(100% - 87px);margin-left: 12px;}
}
@media only screen and (max-width:479px){
    .search-results {padding-right: 6px;}
    .search-banner {padding: 16px 8px 20px;}
    .search-data h2 {font-size: 14px;}
}
@media only screen and (max-width:319px){
    .search-banner {flex-direction: column;}
    .search-data {width: 100%;margin-left: 0px;text-align: center;}
}