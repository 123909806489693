body{
/* background: #02021a !important; */
background-image: url("../../assets/images/header-bg.png");
background-repeat: no-repeat;
background-size: cover;
background-position: center 100%;
height: 100%;
/* width: 100%;
height: 100%; */
overflow-x: hidden;}
.custom-bg{position: relative; background-image: url('../..//assets/images/curvy-bg.png');height: 100%;padding-top: 90px;margin-top: -170px;background-size: cover;background-repeat: no-repeat;}
.custom-heading{font-size: 60px;font-family: 'Outfit-SemiBold';color: #fff;}
.custom-paragraph{font-size: 30px;font-family: 'Outfit-Light';font-weight: 100;color: #fff;}
.heading-xl{font-size: 70px;color: #2558C2;text-transform: uppercase;opacity: 0.3;font-family: 'Outfit-ExtraBold';text-align: center;max-width: 1640px;margin: 0px auto;}
/* .home-page .container,.home-page .container-fluid,.home-page .container-lg,.home-page .container-md,.home-page .container-sm,.home-page .container-xl,.home-page .container-xxl {
    --bs-gutter-x: 0rem;} */
/* .pin-spacer{width: 100% !important;height: auto !important;max-width: 100% !important;}*/
/* .container-fluid{padding: 0px !important;}  */