.community-detail .community-detail-holder{
/* background:#1a1b2b;border-radius:4px; */
background-color: transparent;
}
.community-detail .form-group{margin:0 0 5px;}
.community-detail .form-group label{margin:0 0 20px;font-size:16px;font-weight:bold;font-family: 'Gilroy';}
.community-detail .form-group .form-control{height: 48px;border: none;border-radius: 12px;
/* background: #3e3f55; */
background: rgb(255 255 255 / 13%);
border: 1px solid #a4a4a4;
color: #fff;}
.community-detail .form-group textarea.form-control{
    height:150px;
    /* height:max-content; */
}
.community-detail .cover-image-holder{height:150px;border-radius:10px;overflow: hidden;}
.community-detail .cover-image-holder img{width:100%;height:100%;object-fit: cover;background-position: center center;}