/*full page loader*/

/*.loader-square-49 {display: inline-block;position: relative;transform: rotate(45deg);width: 100px;height: 100px;}
.loader-square-49 > div {width: 60px;height: 60px;position: absolute;animation: anm-SL-49-move 1.3s infinite ease-in;}
.loader-square-49 .sq_1 {border: 2px solid #2b56f5;top: 0;left: 0;}
.loader-square-49 .sq_2 {border: 2px solid #fff;top: 0;left: 68px;}
.loader-square-49 .sq_3 {border: 2px solid #fff;top: 68px;left: 0;}
.loader-square-49 .sq_4 {border: 2px solid #2b56f5;top: 68px;left: 68px;}
@keyframes anm-SL-49-move {
    0% {    position: absolute;}
    50% {    top: 24px;    left: 24px;    position: absolute;    opacity: 0.5;}
    100% {    position: absolute;}
} */

/*new*/
.full-loader {display: flex;justify-content: center;align-items: center;width: 100%;height: 100vh;position: fixed;left: 0;top:0;width: 100%;height: 100vh;background: linear-gradient(90deg,rgb(2, 2, 26),rgb(11, 32, 110));z-index: 100;}
.full-loader .loader{width: 210px;height: 210px;background: linear-gradient(90deg, #0DC7C9, #0074C1);display: flex;flex-wrap: wrap;align-items: center;justify-content: center;border-radius: 8px;animation: none;border: none; box-shadow: 0px 0px 10px #2b56f5;}
.full-loader .loader span{display: inline-block;width: 40px;height: 40px;background: #FFF;border-radius: 50%;margin: 0 60px 0px 0;box-shadow: 60px 0px 0 #FFF;position: relative;}
.full-loader .loader span:after{content: '';width: 100%;height: 100%;background: #000;position: absolute;left: 0;top: 0;border-radius: 50%;box-shadow: 60px 0px 0 #000;animation: closeEyes 15s linear infinite;}
@keyframes closeEyes{0%,2%,4%,49%,51%,50%,79%,81%, 100%{  height: 25%;}3%,50%, 80%{  height: 100%;}}
.full-loader .loader span:hover:after{height: 100%;transition: 0.4s;}
.full-loader .loader span::before{ content: '';height: 20px;width: 20px;border-radius: 50%;background: #000;position: absolute;bottom: 6px;left: 3px;box-shadow: 62px 0 0 #000;animation: moveEyes 2s linear alternate infinite;}
  @keyframes moveEyes{to{  left: 12px;}}
.full-loader p{flex: 0 0 100%;color: #FFF;font-family: sans-serif;text-transform: uppercase;font-weight: 600;text-align: center;letter-spacing: 4px;font-size: 24px; background-clip: text; -webkit-background-clip: text;  -webkit-text-fill-color: rgba(255,255,255,0.3);background-image: linear-gradient(86.3deg,#fff -46.4%,#fff);background-repeat: no-repeat;background-size: 200%;background-position: -100%;animation: loadingText 2s linear alternate infinite;}
  @keyframes loadingText{from{  background-position-x: 200%;}to{  background-position-x: -100%;}}