.code-holder .code-block input{width:100px;height:100px;color:#fff;padding:0 5px;font-size:40px;text-align: center;}
.investor-signup-form input{ height: 48px;border: none;border-radius: 12px;background: rgba(255,255,255,0.13);border: 1px solid #a4a4a4;color: #fff;}
.investor-signup-form input:focus{background: transparent; box-shadow: none;border-color: #fff;color: #fff;}
.investor-signup-form .picture-holder{    border: 5px solid rgb(255 255 255 / 90%); width: 200px;height:200px;border-radius: 50%;overflow: hidden;position: relative; display: inline-block;}
.investor-signup-form .picture-holder img{width:100%;height:100%;object-fit: cover;}
.investor-signup-form .upload-content-area{display: flex;align-items: center;justify-content: center;flex-direction: column;}
.investor-signup-form .upload-area{position: relative;display: inline-block;margin-bottom: 30px;}
.investor-signup-form .upload-btn-area{position: absolute;top: 60%;right: -5%;border: 1px solid #fff; transform: translateY(-50%); cursor: pointer;width: 40px;height: 40px;border-radius: 50px;background-color: #283760;display: flex;align-items: center;justify-content: center;}
.investor-signup-form input[type="file"]{position: absolute;width:135px;height:36px;font-size:0;border:none;background:none;opacity: 0;cursor:pointer;}
@media only screen and (max-width: 767px) {
    .code-holder .code-block input{width:60px;height:60px;font-size:30px;}
}
@media only screen and (max-width: 479px) {
    .code-holder .code-block input{width:35px;height:35px;border-radius:5px;font-size:20px;}
}