.banner{padding-top: 100px; background-image: url('../../../assets/images/header-bg.png');width: 100%;background-size: cover;padding: 240px 0px 0px !important;}
.banner h1{position: relative; font-size: 140px;font-family: 'Outfit-ExtraBold';opacity: 0.3;text-transform: uppercase;color: #2558C2;padding-top: 30px;top: 10%;left: 50%;transform: translateX(-50%);}
.banner h1.sticky {padding-top: 150px;}
.banner .content{color: #fff;}
.banner .content h3{font-size: 90px;font-family: 'Outfit-Bold';}
.banner .content p{font-size: 22px;font-family: 'Outfit-ExtraLight';position: relative;padding-top: 40px;}
.banner .groovy-lottie{position: relative; width: 90%;height: 50px;}
.banner .groovy-lottie  svg{width: 142px !important;}
.banner .groovy-lottie .lottie-content{position: absolute;font-size: 15px;font-family: 'Outfit-SemiBold';right: 30px;}
.banner .groovy-lottie::after{position: absolute;content: "";bottom: 33%; width: 100%;height: 1px;background-color: #253B9C;}
.banner .animated-section{display: inline-block;position: relative;margin-bottom: 50px;}
.banner .outer-circle {position: relative;left: -144px;z-index: 999;backdrop-filter: blur(4px);}
.banner .outer-circle img{width: 126px;}
.banner .inner-circle {position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);}
.banner .inner-circle img{width: 50px;}
.banner .animated-section .tile-image-1,.tile-image-2,.tile-image-3,.tile-image-4 {position: absolute}
.banner .animated-section .tile-image-1{left: -15%;top: 30%;}
.banner .animated-section .tile-image-2{left: 19%;top: -40%;}
.banner .animated-section .tile-image-3{bottom: -40%;left: 18%;}
.banner .animated-section .tile-image-4{right: 36%;bottom: -28%;}
.banner .animated-section .tile-image-1 img{width: 218px;}
.banner .animated-section .tile-image-2 img{width: 137px;}
.banner .animated-section .tile-image-3 img{width: 137px;}
.banner .animated-section .tile-image-4 img{width: 211px;}
.banner .animated-hand{padding: 224px 0px;width: 100%;position: relative;min-height: 85vh;}
.banner .animated-hand .grap{position: relative;}
.banner .animated-hand .globe-1,.banner .animated-hand .globe-2,.banner .animated-hand .globe-3,.banner .animated-hand .globe-4, .banner .animated-hand .globe-5,.banner .animated-hand .grap{position: absolute;}
.banner .animated-hand .globe-1{left: 40%;top: 26%;}
.banner .animated-hand .globe-2{left: 69%;top: 45%;}
.banner .animated-hand .globe-3{right: -24%;top: 35%;}
.banner .animated-hand .globe-4{right: -6%;top:68%;}
.banner .animated-hand .globe-5{left: 30%; bottom: 23%;}
.banner .animated-hand .grap{bottom: -3%;}
.banner .grap h3{position: absolute;top: -20%;left: 13%; font-size: 130px;font-family: 'Outfit-Bold';color: #fff;line-height: 110px;}
.banner .animated-hand .globe-1 img{width: 94px;}
.banner .animated-hand .globe-2 img{width: 94px;}
.banner .animated-hand .globe-3 img{width: 101px;}
.banner .animated-hand .globe-4 img{width: 94px;}
.banner .animated-hand .globe-5 img{width: 94px;}
.banner .animated-hand .hand-image img {margin-left: 30px;mix-blend-mode: color-dodge;opacity: 0.3;width: 728px;}
.organi,.creatives,.customize-para-one{display: none;}
/* .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {--bs-gutter-x:0rem;} */

/**/
@media only screen and (max-width: 991px) {
    .banner {padding: 150px 0px 0px !important;}
}
@media only screen and (max-width: 767px) {

}
@media only screen and (max-width: 575px) {

}

