.proportional-area .proportional-card{ animation: MoveUpDown 3s linear infinite; margin: 0px 60px 30px 0; overflow: visible; position: relative; padding-top: 30px;padding-left: 30px;border-radius: 18px;max-width: 240px;max-height: 320px;min-height: 320px;color: #fff;}
.proportional-area .proportional-card-one{ background:transparent linear-gradient(180deg, #0074C1 0%, #0F0F2200 100%) 0% 0% no-repeat padding-box;}
.proportional-area .proportional-card-two{margin-top: 70px; background: transparent linear-gradient(180deg, #2558C200 0%, #132C61 100%) 0% 0% no-repeat padding-box;}
.proportional-area .proportional-card .content{position: absolute;right: -30%;top: 60%; transform: translateY(-50%);}
@keyframes MoveUpDown {
    0%, 100% {
      bottom: 0;
    }
    50% {
      bottom: 50px;
    }
  }
.proportional-area .proportional-card .proportional-title{font-size: 25px;font-family: 'Outfit-Regular';white-space: nowrap;font-weight: normal;}
.proportional-area .proportional-card .proportional-value{font-size: 50px;font-family: 'Outfit-Bold';}
.proportional-area .proportional-card img{width: 55px;}
@media screen and (min-width: 1920px){.proportional-area > .container{max-width: 1641px !important;}}