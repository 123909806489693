.create-proposal .proposal-form {
/* background: #2e2f44; */
background: rgba(12, 44, 111,0.5);
border-radius: 8px;min-height: 717px;padding: 44px 20px; color: #fff;}
.proposal-form p {font-size: 12px;color: #fff;padding: 0 6px;font-family: 'Gilroy';font-weight: 600;}
.proposal-listing .not-found{width: 100%;min-height: 664px;
/* background: #2e2f44; */
background: rgba(12, 44, 111,0.5);
/* box-shadow: 0px 4px 32px rgb(0 0 0 / 8%); */border-radius: 8px;color: #fff;}
.create-proposal .proposal-form h2 {font-family: 'Gilroy';font-weight: bold;font-size: 24px;color: #fff;margin-bottom: 30px !important;text-align: center;}
.create-proposal .proposal-form .input {width: 440px;margin-bottom: 18px;}
.create-proposal .proposal-form .textarea {width: 440px;margin-bottom:18px;}
.accordion .accordion-item h2{margin-bottom: 0%  !important;}
.proposal-form .input input,
.proposal-form .textarea textarea {
/* background: #3e3f55;border: none; */
background: rgb(255 255 255 / 13%);
border: 1px solid #a4a4a4;
border-radius: 12px;width: 100%;padding: 12px;}
.proposal-form .textarea textarea {min-height: 144px;color: #fff;}
.proposal-form .input input,
.proposal-form .textarea textarea ::placeholder {color: #fff;font-family: 'Gilroy';font-weight: normal;font-size: 16px;}
.proposal-form textarea:focus {outline: none !important;}
.create-proposal button:disabled,
.vote-cast button:disabled {background: #E0E0E0;border-radius: 12px;border: 1px solid #E0E0E1;color: #BDBDBD;cursor: not-allowed;}
/* .create-proposal {display: none;} */
.profile-created{
    text-align: center;
    min-height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.profile-created span{font-size: 33px;}
.created-successfully {
/* padding: 168px 0 145px; */
padding: 100px 20px;
background: #2e2f44;border-radius: 8px;min-height: 500px;text-align: center;}
.created-successfully .frame {margin-bottom: 20px;}
.created-successfully p {color: #fff;font-family: 'Gilroy';font-weight: 600;font-size: 16px;margin-bottom: 16px;}
/* .proposal-listing {margin: 0 40px 0 20px;} */
.proposal-listing .top-heading .page-header {min-height: 60px;}
.create-proposal .page-header {min-height: 60px;}
input.ReactTags__tagInputField {width: 100%;}
.proposal-listing .poolList-1,
.vote-pool {
/* background: #3e3f55; */
background: rgba(12, 44, 111,0.5);
border-radius: 8px;min-height: 280px;margin-bottom: 16px;box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);}
.pooling1 {border-left: 12px solid #2b56f5;}
.poolList-1 button {padding: 12px 25px !important;border-radius: 12px;font-size: 16px;line-height: 19px;font-family: 'Gilroy';font-weight: 600;/* min-width: 200px; */}
.poolList-1 button span {font-size: 16px;line-height: 19px;font-family: 'Gilroy';font-weight: 600;color: #FFFFFF}
.proposal-listing .poolList-1 .content,
.vote-pool .content {padding: 24px 30px 30px 52px;position: relative;}
.proposal-listing .poolList-1 h2,
.vote-pool h2 {/* color: #1A1A1A; */font-family: 'Gilroy';font-weight: bold;font-size: 24px;background: -webkit-linear-gradient(#0bbbc7, #017ac1);background-clip: text;-webkit-background-clip: text;-webkit-text-fill-color: transparent;}
.proposal-listing .poolList-1 span,
.voting .content .time-left {color: #fff;font-family: 'Gilroy';font-weight: bold;font-size: 22px;}
.proposal-listing .poolList-1 span.timer{min-width:100px;}
.cancel-btn {min-width: 164px !important;border-radius: 12px;padding: 14px 23px 13px;font-size: 16px;line-height: 19px;font-family: 'Gilroy';font-weight: 600}
.proposal-listing .poolList-1 .top-head,
.vote-pool .top-head {margin-bottom: 24px;}
.proposal-listing .poolList-1 .body span,
.proposal-listing .poolList-1 .body strong,
.vote-pool span,
.vote-pool strong{color: #fff;font-family: 'Gilroy';font-weight: bold;font-size: 17px;margin-bottom: 70px;}
.proposal-listing .poolList-1 p,
.vote-pool p {color: #fff;font-family: 'Gilroy';font-weight: normal;font-size: 15px;}
.voting {border-left: 12px solid #2b56f5;border-top-left-radius: 12px;}
.voting .content {padding: 21px 30px 18px 52px;}
.poolList-1 .footer span {font-family: 'Gilroy';font-weight: 500;font-size: 18px;}
.poolList-1 .footer ul li .abstain {color: #828282 !important;font-family: 'Gilroy';font-weight: 500;font-size: 18px;}
.pool2 {position: relative;margin-bottom: 21px;}
.pool2 .footer {padding-bottom: 32px;}
.pool2::before {position: absolute;/* background-color: #2b56f5; */border: 8px solid #2b56f5;content: '';height: 10%;top: 24px;border-radius: 0%;}
.pool-2 {margin-bottom: 30px;text-align: start;}
.pool-2 p{width: 100%;}
.vote-cast {padding: 65px 0 125px 0;text-align: center;}
.vote-cast h2 {color: #1A1A1A;font-family: 'Century Gothic';font-style: normal;font-weight: 700;font-size: 32px;margin-bottom: 32px !important;}
.vote-cast .box {background: #FFFFFF;border: 1px solid #E0E0E0;border-radius: 8px;min-width: 128px;min-height: 128px;padding: 12px 12px 6px 12px;margin:0 10px;}
.vote-cast .box span {color: #1A1A1A;font-family: 'Gilroy';font-weight: 500;font-size: 18px}
.vote-cast .box .disable {display: none;}
.vote-cast .box .active {display: none;}
.vote-cast .box .check {width: 24px;height: 24px;border-radius: 100%;background-color: transparent;border: 2px solid #BDBDBD;float: right}
.vote-cast .boxes {margin-bottom: 96px;}
.vote-recorded {padding: 65px 0 127px 0;text-align: center}
.vote-recorded h2,
.vote-result h2 {color: #1A1A1A;font-family: 'Century Gothic';font-style: normal;font-weight: 700;font-size: 32px;margin-bottom: 33px !important;}
.vote-recorded .frame {margin-bottom: 33px;}
.vote-recorded p {color: #fff;font-family: 'Gilroy';font-weight: 600;font-size: 16px;margin-bottom: 42px;text-align: center;width: 100%;}
.vote-result {padding: 30px 0;text-align: center;}
.vote-result .box {background: #F2FAF5;border-radius: 8px;width: 210px;min-height: 143px;padding: 18px 0 11px;margin: 10px;}
.vote-result .box span {color: #828282;font-family: 'Gilroy';font-weight: normal;font-size: 24px;margin: 0;display: block;}
.vote-result .box strong {color: #1A1A1A;font-family: 'Gilroy';font-weight: bold;font-size: 52px;}
.vote-result .boxes {margin-bottom: 55px;}
/* .vote-result .voting-section {margin-bottom: 50px;} */
.vote-cast .box .form-check input {padding: 10px;float: right;}
.vote-cast .box .form-check-input:checked[type=radio] {background-image: url('../../assets/images/tick.svg');background-size: 10px;}
.vote-cast .box .form-check-input:checked {background-color: #2b56f5;border-color: #2b56f5;}
.vote-cast .box:hover,
.vote-cast .box:active {border: 1px solid #2b56f5;border-radius: 8px;}
.vote-cast .form-check-input:focus {box-shadow: none;}
.loader {border: 2px solid #f3f3f3;/* Light grey */border-top: 2px solid #2b56f5;border-radius: 50%;width: 20px;height: 20px;animation: spin 2s linear infinite;}
.create-proposal .accordion-item{
/* background-color: #3e3f55; */
background: rgb(255 255 255 / 13%);
/* border: 1px solid #a4a4a4; */
border-radius: 12px;}
.proposal-form .textarea textarea:focus-within{border: 1px solid #ffff;}
.proposal-form .input input:focus{border: 1px solid #ffff;}
.fields-inputs-and-accordion{width: 100%;}
.fields-inputs-and-accordion .d-flex.justify-content-center.align-items-center .input,.fields-inputs-and-accordion .d-flex.justify-content-center.align-items-center .textarea{width: 100%;}
.create-proposal.create-community .proposal-form{height: 100%;min-height: 100%;}
.create-proposal .freedom-select__control{    min-height: 48px !important;border-radius: 12px !important;}
.create-proposal button.accordion-button.collapsed{align-items: baseline;}
.create-proposal .accordion-item{padding: 13px 20px;}
/**/
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 1399px) {

    .proposal-listing .poolList-1 p,
    .vote-pool p {
        width: 100%;
    }

    .pool-2 {
        margin-bottom: 0px;
    }

    .voting-section img {
        width: 20px;
    }

    .poolList-1 .footer ul li .abstain,
    .poolList-1 .footer span {
        font-size: 14px;
    }
}

@media (max-width: 820px) {

    .proposal-listing .poolList-1 .content
    {
        /* padding: 24px 30px 0px 52px !important; */
    }
    .vote-pool .content {
        padding: 15px 30px 48px 42px !important;
    }

    .poolList-1 .footer ul li .abstain,
    .poolList-1 .footer span {
        font-size: 12px;
    }
    .proposal-listing .poolList-1 .content{
        padding: 24px 30px 30px 22px;
    }
}

@media (max-width: 575px) {

    .proposal-listing .poolList-1 p,
    .vote-pool p {
        font-size: 13px;
    }

    .proposal-listing .poolList-1 .content,
    .vote-pool .content {
        /* padding: 24px 30px 10px 52px !important; */
        padding: 24px 20px 10px 25px !important;
    }
    .create-proposal .proposal-form .input{width: 100%;}
    .proposal-listing .poolList-1 h2,
    .vote-pool h2 {
        font-size: 20px;
        min-height: 50px;
    }

    .vote-cast .boxes {
        margin-bottom: 40px;
    }

    .proposal-listing .poolList-1 span,
    .voting .content .time-left {
        font-size: 18px;
    }

    .proposal-listing .top-heading button {
        width: 100%;
    }

    .proposal-listing .poolList-1 .body span,
    .vote-pool span {
        margin-bottom: 30px;
    }

    .vote-recorded p {
        font-size: 14px;
    }

    .pool2 .footer {
        padding-bottom: 96px;
    }

    .vote-result .box {
        margin-bottom: 15px;
    }

    .vote-cast .boxes .box {
        margin-bottom: 12px;
    }
}

@media (max-width: 479px) {
    /* .vote-result .box {
        margin-right: 0% !important;
    } */
    .poolList-1 button {
        min-width: 153px;
        margin-left: 0 !important;
        padding: 0% !important;
    }
}

@media (max-width: 320px) {
    .vote-result .box {
        width: 140px;
    }

    .vote-result .box strong {
        font-size: 30px;
    }

    .vote-result .box span {
        font-size: 18px;
    }
}

@media (max-width: 280px) {
    .vote-result .box {
        margin-right: 0% !important;
    }
    .proposal-listing .poolList-1 h2{min-height: 75px;}
    .stamp-date {
        top: 166px !important;
    }
    .vote-cast .boxes .box {
        /* margin-bottom: 12px; */
        margin-right: 0% !important;
    }

    .freedom-btn {

        font-size: 12px;

        min-width: 154px;
    }
}